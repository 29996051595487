import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { NotifyComponent } from 'src/app/shared/components/common/notify/notify.component';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthorInfo } from 'src/app/shared/class/global';
import { LoginData, RespondeLogin, RegisterData } from 'src/app/login/interfaces/interfaces';
import {
  UserSetting,
  TypeLayout, UserInfo,
} from '../common/main-viewer-common';
import Util from '../utils/util';
import { IdentityService } from './api/identity.service';
import { NotifiedData, StatusNotify } from '../common/global';
import { SystemService } from './api/system.service';
import { GoogleAnalyticsEventsService } from './google-analytic-events-service';
import { CommonConstant } from '../constant/constant';
import { UtilExtend } from '../utils/util-extend';
import { WebServerService } from './api/webserver.service';
import { StreamService } from './api/stream.service';
import { ConversionService } from './api/conversion.service';
import { BimService } from './api/bim.service';
@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private loginName: string = null;

  public isLoading = new BehaviorSubject<boolean>(false);

  public isLoading$ = this.isLoading.asObservable();

  public get userName(): string {
    return this.userInfo?.name ?? Util.USER_NAME;
  }

  public userInfo: UserSetting;

  translations = {
    LOGIN: {
      THE_USER_ALREADY_EXISTS: 'The user already exists.',
      THE_LOGIN_NAME_IS_NOT_EXISTS: 'The login name is not exists.',
      PASSWORD_WRONG: 'Password wrong.',
      SIGN_IN_SUCCESS: 'Sign in success !',
    },
  };

  private userInfoDefault: UserSetting = {
    name: Util.USER_NAME,
    token: '',
    info: {
      id: '',
    },
  };

  constructor(
    private webServerService: WebServerService,
    private streamService: StreamService,
    private conversionService: ConversionService,
    private bimService: BimService,
    private identityService: IdentityService,
    private router: Router,
    private snackBar: MatSnackBar,
    private googleAnalytic: GoogleAnalyticsEventsService,
  ) {}

  setNeedIdentity(needIdentity: boolean) {
    this.identityService.isNeedIdentity = needIdentity;
  }

  login(loginData: LoginData) {
    this.isLoading.next(true);
    this.identityService
      .login(loginData)
      .subscribe((responde: RespondeLogin) => {
        this.googleAnalytic.emitEvent('login', {
          description: JSON.stringify(responde),
        });
        if (responde && responde.errorCode === 0) {
          this.logInSuccess(loginData, responde);
        } else {
          this.loginFail(responde.msg);
        }
      });
  }

  logout() {
    this.identityService.logout().subscribe(
      (responde: string) => {
        this.clearUserStorage();
        this.googleAnalytic.emitEvent('logout', {
          description: JSON.stringify(responde),
        });
      },
      (err) => this.errorLogout(err),
    );
  }

  public navigateToOpen() {
    const valueLoginSuccessLocal = Util.getValueLocalStorage(CommonConstant.LoginSuccess);
    if (valueLoginSuccessLocal !== null && valueLoginSuccessLocal) {
      if (this.checkUserToken()) {
        this.router.navigateByUrl('open');
      }
    }
  }

  public clearUserStorage(fullClear = true) {
    this.navigateLoginScreen();
    UtilExtend.clearUserStorage(fullClear);
    this.userInfo = null;
  }

  private errorLogout(er: any) {
    if (er === 401) {
      this.clearUserStorage(false);
    }
  }

  logInSuccess(data: LoginData, res: RespondeLogin, mesg: string = null) {
    const userSetting: UserSetting = {
      name: data.loginName,
      layout: TypeLayout.Full,
      token: res.token,
      info: res.info,
    };
    this.setUserSetting(userSetting);
    this.setUserNameStorage(data.loginName);
    this.logInSuccessExtend(data.loginName, res.token, mesg);
  }

  register(data: RegisterData) {
    return new Observable<any>((obser) => {
      this.isLoading.next(true);
      this.identityService.register(data).subscribe(
        (res: RespondeLogin) => {
          this.googleAnalytic.emitEvent('login', {
            description: JSON.stringify(res),
          });
          if (res && res.errorCode === 0) {
            const temp: LoginData = {
              loginName: data.userName,
              password: data.password,
            };
            this.logInSuccess(temp, res, 'Sign up success !!');
          } else {
            const mesg = res.msg ?? 'Something went wrong';
            this.signUpFail(mesg);
            obser.next(mesg);
            obser.complete();
          }
        },
        (error) => {
          this.signUpFail('Something went wrong');
          obser.next(error);
          obser.complete();
        },
      );
    });
  }

  getMessage(mss: string):string {
    let message = '';
    switch (mss) {
      case 'The user already exists.':
        message = this.translations?.LOGIN?.THE_USER_ALREADY_EXISTS;
        break;
      case 'The login name is not exists.':
        message = this.translations?.LOGIN?.THE_LOGIN_NAME_IS_NOT_EXISTS;
        break;
      case 'Password wrong.':
        message = this.translations?.LOGIN?.PASSWORD_WRONG;
        break;
      case 'Sign in success !':
        message = this.translations?.LOGIN?.SIGN_IN_SUCCESS;
        break;
      default:
    }
    return message;
  }

  private signUpFail(message: string) {
    const mss = this.getMessage(message);
    const data: NotifiedData = {
      message: mss,
      status: StatusNotify.error,
    };
    this.notified(data);
    this.isLoading.next(false);
  }

  setUserSetting(userData: UserSetting) {
    if (userData.name) {
      const newKey = this.upperCaseKey(userData.name);
      this.userInfo = userData;
      Util.setLocalStorage(newKey, userData);
      return true;
    }
    return false;
  }

  getUserSetting(loginName: string) {
    if (loginName) {
      const keyUsername = this.upperCaseKey(loginName);
      return Util.getValueLocalStorage(keyUsername);
    }
    return false;
  }

  checkUserToken(): boolean {
    const info = this.getUserInfo();
    if (info.token && info.token !== '') {
      if (this.setUserSetting(info)) {
        this.updateTokenService(info.token);
      }
      return true;
    }
    // this.navigateLoginScreen();
    return false;
  }

  navigateLoginScreen() {
    if (this.router.url !== '/login') {
      Util.setLocalStorage(CommonConstant.UrlInit, this.router.url);
      this.router.navigateByUrl('login');
    }
  }

  getUserInfo(): UserSetting {
    if (!this.userInfo) {
      const userName = Util.getValueLocalStorage(CommonConstant.UserNameKey);
      if (userName) {
        const userInfoLocal = this.getUserSetting(userName);
        return userInfoLocal ?? this.userInfoDefault;
      }
      return this.userInfoDefault;
    }
    return this.userInfo;
  }

  private setUserNameStorage(userName: string) {
    Util.setLocalStorage(CommonConstant.UserNameKey, userName);
  }

  private upperCaseKey(para: string = null): string {
    const input = para ?? this.loginName;
    return `${input.toUpperCase()}_${CommonConstant.NameUserSetting}`;
  }

  private logInSuccessExtend(
    loginName: string,
    token: string,
    message: string = null,
  ) {
    const tmss = message || 'Sign in success !';
    const mss = this.getMessage(tmss);
    this.updateTokenService(token);
    this.setLoginSuccessLocalStorage(true);
    const dataSuccess: NotifiedData = {
      message: mss,
      status: StatusNotify.success,
    };
    const notifyRef = this.notified(dataSuccess, 0.4);
    const sub = notifyRef.afterDismissed().subscribe((_) => {
      this.signIn(loginName);
      sub.unsubscribe();
    });
  }

  private signIn(loginName: string) {
    this.loginName = loginName;
    const urlInit = Util.getValueLocalStorage(CommonConstant.UrlInit);
    if (urlInit) {
      this.router.navigateByUrl(urlInit);
    } else {
      this.router.navigateByUrl('open');
    }
  }

  private loginFail(msg: string) {
    const data: NotifiedData = {
      // message: 'Username or password is incorrect !!!',
      message: this.getMessage(msg),
      status: StatusNotify.error,
    };
    this.notified(data);
    this.isLoading.next(false);
  }

  public notified(
    data: NotifiedData = null,
    duration: number = 5,
  ): MatSnackBarRef<NotifyComponent> {
    return this.snackBar.openFromComponent(NotifyComponent, {
      panelClass: 'custom-container-notify',
      data,
      duration: duration * 1000,
    });
  }

  public updateTokenService(token: string) {
    if (token) {
      this.webServerService.updateToken(token);
      this.streamService.updateToken(token);
      this.conversionService.updateToken(token);
      this.bimService.updateToken(token);
      this.identityService.updateToken(token);
    }
  }

  loginAsAnonymous() {
    this.userInfo = this.userInfoDefault;
    this.router.navigate(['/open']);
  }

  public setLoginSuccessLocalStorage(value: boolean) {
    Util.setLocalStorage(CommonConstant.LoginSuccess, value ?? false);
  }

  public getUserInfoExtend(): UserInfo {
    if (this.userInfo) {
      return {
        id: this.userInfo.info.id ?? null,
        userName: this.userInfo.name ?? Util.USER_NAME,
        loginName: Util.LOGIN_NAME ?? "",
      };
    }
    return {
      id: this.userInfoDefault.info.id ?? null,
      userName: this.userInfoDefault.name ?? Util.USER_NAME,
      loginName: Util.LOGIN_NAME ?? "",
    };
  }

  updateDefaultInformation(username: string) {
    this.userInfoDefault.name = username;
  }
}
