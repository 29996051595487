/* eslint-disable no-restricted-syntax */
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { map, tap } from 'rxjs/operators';
import { SystemConstants } from '../../common/system.constants';
import { APIService } from './base/APIBase.service';
import Util from '../../utils/util';
import { LoginService } from '../login.service';
import { AdeptInfor } from '../../common/AdeptInfor';
import { FileInfo } from '../../common/main-viewer-common';
import { UtilExtend } from '../../utils/util-extend';

@Injectable({
  providedIn: 'root',
})
export class AdeptService extends APIService {
  private IDENTITY: any;

  private keyStorage = 'IDENTITY';

  public adeptViewItems: AdeptInfor[] = [];

  // da khai bao trong base
  // public BaseApi = `${SystemConstants.PROTOCOL}://${SystemConstants.SERVER_ADEPT.HOST}`;

  constructor(
    public http: HttpClient,
    public router: Router,
    private loginService: LoginService
  ) {
    super(http, router);
    this.IDENTITY = Util.getValueLocalStorage(this.keyStorage);
  }

  isLogin() {
    return this.IDENTITY != null;
  }

  getToken() {
    return `${this.IDENTITY.token_type} ${this.IDENTITY.access_token}`;
  }

  async login(username, password) {
    this.IDENTITY = await this.post(
      SystemConstants.SERVER_ADEPT.API.login.endpoint,
      {
        username,
        password,
        cultureName: 'en-US',
        grant_type: 'password',
        client_id: 'Adept',
        forceLogin: true,
        connectionId: Communicator.UUID.create(),
        timeZoneOffset: -420,
      }
    ).toPromise();
    Util.setLocalStorage(this.keyStorage, this.IDENTITY);
    return this.IDENTITY;
  }

  async refreshTokenNoneUser() {
    const userInfor = this.loginService.getUserInfoExtend();
    const userName = userInfor?.loginName;
    const clientId = userInfor?.id;
    // if (SystemConstants.HOST_DEV === 'DEV') {
    //   // const baseApi = SystemConstants.SERVER_ADEPT.HOST.includes('http') ? SystemConstants.SERVER_ADEPT.HOST : `http://${SystemConstants.SERVER_ADEPT.HOST}`;
    //   // this.IDENTITY = await this.postExt(baseApi, SystemConstants.SERVER_ADEPT.API.login.endpoint, {
    //   //   username: userName || 'Viewer',
    //   //   grant_type: 'password',
    //   //   client_id: clientId || 'Viewer',
    //   //   client_secret: 'Viewer',
    //   // }).toPromise();
    // } else {
    //   this.IDENTITY = await this.post(SystemConstants.SERVER_ADEPT.API.login.endpoint, {
    //     username: userName || 'Viewer',
    //     grant_type: 'password',
    //     client_id: clientId || 'Viewer',
    //     client_secret: 'Viewer',
    //   }).toPromise();
    // }
    if (!Util.isPreventSendData()) {
      this.IDENTITY = await this.post(
        SystemConstants.SERVER_ADEPT.API.login.endpoint,
        {
          username: userName || 'Viewer',
          grant_type: 'password',
          client_id: clientId || 'Viewer',
          client_secret: 'Viewer',
        },
      ).toPromise();
    }

    Util.setLocalStorage(this.keyStorage, this.IDENTITY);
    return this.IDENTITY;
  }

  async refreshToken() {

    if (!this.IDENTITY || !this.IDENTITY.refresh_token) {
      await this.refreshTokenNoneUser();
    }

    this.IDENTITY = await this.post(
      SystemConstants.SERVER_ADEPT.API.refreshToken.endpoint,
      {
        loginName: this.IDENTITY ? this.IDENTITY.userName : '',
        grant_type: 'refresh_token',
        refresh_token: atob(this.IDENTITY ? this.IDENTITY.refresh_token : ''), // decode base64
        client_id: 'Viewer',
        client_secret: 'Viewer',
        connectionId: this.IDENTITY ? this.IDENTITY.connectionId : '',
        autoLogin: null,
      },
      true
    ).toPromise();
    Util.setLocalStorage(this.keyStorage, this.IDENTITY);
  }

  async unlockMarkups(files: FileInfo[]) {
    let params = ``;
    files.forEach((file) => {
      const adeptItem = this.adeptViewItems.filter(
        (i) => UtilExtend.sameFileIdRev(i, file)
      )[0];
      params =
        params +
        `values=${encodeURIComponent(
          adeptItem?.baseFileId +
            '.' +
            adeptItem?.baseMajorRev +
            '.' +
            adeptItem?.baseMinorRev
        )}.${encodeURIComponent(adeptItem?.viewId)}&`;
    });

    await this.getAPI(
      `${SystemConstants.SERVER_ADEPT.API.unlockMarkup.endpoint}?${params}`
    );
  }

  async getUserInfo() {
    const result = await this.getAPI(
      SystemConstants.SERVER_ADEPT.API.userinfo.endpoint
    );
    return result;
  }

  async getInfoFile(tableNumber, file) {
    await this.refreshToken();
    let result = null;
    if (this.IDENTITY) {
      result = await this.getAPI(
        `${SystemConstants.SERVER_ADEPT.API.getInfoFile.endpoint}/${tableNumber}/${file.baseFileId}/${file.baseMajorRev}/${file.baseMinorRev}`
      );
    }
    return result;
  }

  async publishPDFOpenInBrowser(fileId: string, majRev: number, minRev: number) {
    await this.putAPI(
      `${SystemConstants.SERVER_ADEPT.API.publishPDFOpenInBrowser.endpoint}/${fileId}/${majRev}/${minRev}`,
      {},
    );
  }

  async publishPDFSave(fileId: string, majRev: number, minRev: number) {
    await this.putAPI(
      `${SystemConstants.SERVER_ADEPT.API.publishPDFSave.endpoint}/${fileId}/${majRev}/${minRev}`,
      {},
    );
  }

  async saveMarkupData(
    dataPost: {
      baseFileId: string;
      markupData: string;
      userId: string;
      baseMajorRev: number;
      baseMinorRev: number;
      markupIsDirty: boolean;
      viewId: string;
    }[]
  ) {
    dataPost.forEach((d) => {
      const adeptItem = this.adeptViewItems.filter((s) => {
        const b = UtilExtend.sameFileIdRev(s, d);
        return b;
      })[0];
      d.viewId = adeptItem.viewId;
    });

    if (
      !this.IDENTITY
      || Date.now() - new Date(this.IDENTITY['.expires']).getTime()
        > this.IDENTITY.expires_in
    ) { await this.refreshTokenNoneUser(); }
    let result = null;
    if (this.IDENTITY && dataPost?.length) {
      dataPost.forEach((data) => {
        data.userId = this.IDENTITY.userId ?? data.userId;
        if (this.IDENTITY.displayName) {
          data.userId = this.IDENTITY.displayName;
        }
      });
      // if (SystemConstants.HOST_DEV === 'DEV') {
      //   // const baseApi = SystemConstants.SERVER_ADEPT.HOST.includes('http') ? SystemConstants.SERVER_ADEPT.HOST : `http://${SystemConstants.SERVER_ADEPT.HOST}`;
      //   // result = await this.putAPIExt(baseApi, SystemConstants.SERVER_ADEPT.API.saveMarkup.endpoint, dataPost);
      // } else result = await this.putAPI(SystemConstants.SERVER_ADEPT.API.saveMarkup.endpoint, dataPost);
      if (!Util.isPreventSendData()) {
        result = await this.putAPI(
          SystemConstants.SERVER_ADEPT.API.saveMarkup.endpoint,
          dataPost,
        );
      }
    }
    return result;
  }

  async logout() {
    Util.removeItemLocalStorage(this.keyStorage);
    await this.refreshToken();
    let result = null;
    if (this.IDENTITY) {
      result = await this.getAPI(
        SystemConstants.SERVER_ADEPT.API.logout.endpoint +
          (this.IDENTITY ? this.IDENTITY.connectionId : null)
      );
    }
    return result;
  }

  post(uri: string, data: any, isFormData = false): Observable<any> {
    if (this.IDENTITY) {
      this.httpOptions.headers = this.httpOptions.headers.set(
        'Authorization',
        this.getToken()
      );
    }
    const body = this.createHttpParam(data);
    return super.post(uri, body);
  }

  postExt(
    strBaseApi: string,
    uri: string,
    data: any,
    isFormData = false
  ): Observable<any> {
    if (this.IDENTITY) {
      this.httpOptions.headers = this.httpOptions.headers.set(
        'Authorization',
        this.getToken()
      );
    }
    const body = this.createHttpParam(data);
    return super.postExt(strBaseApi, uri, body);
  }

  async getAPI(uri: string) {
    if (
      !this.IDENTITY ||
      Date.now() - new Date(this.IDENTITY['.expires']).getTime() >
        this.IDENTITY.expires_in
    )
      await this.refreshTokenNoneUser();
    if (this.IDENTITY) {
      this.httpOptions.headers = this.httpOptions.headers.set(
        'Authorization',
        this.getToken()
      );
    }
    const result = await super.get(uri).toPromise();
    return result;
  }

  async putAPI(uri: string, data: any, isFormData = false) {
    // this.BaseApi = `${SystemConstants.PROTOCOL}://${SystemConstants.SERVER_ADEPT.HOST}`;
    if (
      !this.IDENTITY ||
      Date.now() - new Date(this.IDENTITY['.expires']).getTime() >
        this.IDENTITY.expires_in
    )
      await this.refreshTokenNoneUser();
    if (this.IDENTITY) {
      this.httpOptions.headers = this.httpOptions.headers.set(
        'Authorization',
        this.getToken()
      );
    }
    const body = isFormData ? this.createHttpParam(data) : data;
    const result = await this.put(uri, body).toPromise();
    return result;
  }

  async postAPI(uri: string, data: any, isFormData = false) {
    // this.BaseApi = `${SystemConstants.PROTOCOL}://${SystemConstants.SERVER_ADEPT.HOST}`;
    if (
      !this.IDENTITY ||
      Date.now() - new Date(this.IDENTITY['.expires']).getTime() >
        this.IDENTITY.expires_in
    )
      await this.refreshTokenNoneUser();
    if (this.IDENTITY) {
      this.httpOptions.headers = this.httpOptions.headers.set(
        'Authorization',
        this.getToken()
      );
    }
    const body = isFormData ? this.createHttpParam(data) : data;
    const result = await this.post(uri, body).toPromise();
    return result;
  }

  async putAPIExt(
    strBaseApi: string,
    uri: string,
    data: any,
    isFormData = false
  ) {
    // this.BaseApi = `${SystemConstants.PROTOCOL}://${SystemConstants.SERVER_ADEPT.HOST}`;
    if (
      !this.IDENTITY ||
      Date.now() - new Date(this.IDENTITY['.expires']).getTime() >
        this.IDENTITY.expires_in
    )
      await this.refreshTokenNoneUser();
    if (this.IDENTITY) {
      this.httpOptions.headers = this.httpOptions.headers.set(
        'Authorization',
        this.getToken()
      );
    }
    const body = isFormData ? this.createHttpParam(data) : data;
    const result = await this.putExt(strBaseApi, uri, body).toPromise();
    return result;
  }

  createHttpParam(data): HttpParams {
    let body = new HttpParams();
    if (data) {
      for (const prop in data) {
        if (typeof data[prop] !== 'object') {
          body = body.append(prop, data[prop]);
        } else {
          body = body.append(prop, this.createHttpParam(data[prop]).toString());
        }
      }
    }
    return body;
  }

  postPrintParamNew(data, exportDirect, export3dAs2d, overwrite, outputPngResolution) {
    const url = `${new URL(this.BaseApi).origin}/Conversion/${SystemConstants.SERVER_CONVERSION.API.PRINT.PRINT_POST_NEW}`;
    return this.http.post(`${url}?${Util.parseUrl({exportDirect, export3dAs2d, overwrite, output_png_resolution: outputPngResolution})}`, data, { ...this.httpOptions, responseType: 'text' });
  }

  getCurrentViewSection(viewIdData) {
    const { token } = this.loginService.getUserInfo();
    const auth = token ? `Bearer ${token}` : '';
    // this.BaseApi = viewIdData.URL.includes('http')
    //   ? viewIdData.URL
    //   : `http://${viewIdData.URL}`;
    this.setBaseApi(SystemConstants.PROTOCOL, viewIdData.URL);
    return this.getFromUrl(
      `${this.BaseApi}/${
        SystemConstants.SERVER_ADEPT.API.getSection.endpoint
      }?${Util.parseUrl({ id: viewIdData.VIEWID })}`,
      {
        'Content-Type': 'application/json',
        // Authorization: auth,
        Authorization: `${
          !viewIdData.AUTHCODE.includes('Bearer') &&
          !viewIdData.AUTHCODE.includes('bearer')
            ? 'Bearer'
            : ''
        } ${viewIdData.AUTHCODE}`,
      }
    ).pipe(
      map((d) => d as AdeptInfor[]),
      tap((d) => {
        d.forEach((i) => {
          if (d[0].viewItemParameters.markupData.startsWith('"')) {
            d[0].viewItemParameters.markupData = JSON.parse(
              d[0].viewItemParameters.markupData
            );
          }

          i.viewId = viewIdData.VIEWID;
          this.adeptViewItems.push(i);
        });
      })
    );
  }

  addFile2ViewSection(VIEWID, d: AdeptInfor[]) {
    d.forEach((i) => {
      if (d[0].viewItemParameters.markupData.startsWith('"')) {
        d[0].viewItemParameters.markupData = JSON.parse(
          d[0].viewItemParameters.markupData
        );
      }

      i.viewId = VIEWID;
      this.adeptViewItems.push(i);
    });
  }

  async updateUserSettings(dataPost) {
    let result = '';
    // if (SystemConstants.HOST_DEV === 'DEV') {
    //   const baseApi = SystemConstants.SERVER_ADEPT.HOST.includes('http') ? SystemConstants.SERVER_ADEPT.HOST : `http://${SystemConstants.SERVER_ADEPT.HOST}`;
    //   // result = await this.putAPIExt(baseApi,
    //   //   SystemConstants.SERVER_ADEPT.API.saveUserSettings.endpoint, dataPost);
    // } else result = await this.putAPI(SystemConstants.SERVER_ADEPT.API.saveUserSettings.endpoint, dataPost);
    if (!Util.isPreventSendData()) {
      result = await this.putAPI(
        SystemConstants.SERVER_ADEPT.API.saveUserSettings.endpoint,
        dataPost,
      );
    }
    return result;
  }

  checkConnection(): boolean {
    return navigator.onLine;
  }
}
