<section cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container" class="header"
  cdkDragHandle>
  <span>{{'DIALOG_SYMBOL.SCALING'|translate}}</span>
  <span class="spacer"></span>
  <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon"
    (click)="closeDialog(false)" tabindex="-1">
    <mat-icon>close</mat-icon>
  </button>
</section>
<mat-divider></mat-divider>
<section class="container">
  <section class="group-setting">
    <div class="group-title title-toggle">
      <span>
        {{ "DIALOG_SYMBOL.SCALING" | translate }}</span>
    </div>
    <section class="container-item in-group">
      <mat-radio-group aria-label="Select an option" [(ngModel)]="selectedScaling">
        <mat-radio-button [value]="eScaling.ISOTROPIC" [tabIndex]="-1">{{ 'DIALOG_SYMBOL.ISOTROPIC' | translate
          }}</mat-radio-button>
        <mat-radio-button [value]="eScaling.ANISOTROPIC" [tabIndex]="-1">{{ 'DIALOG_SYMBOL.ANISOTROPIC' | translate
          }}</mat-radio-button>
      </mat-radio-group>
    </section>
  </section>
</section>
<div class="footer">
  <button mat-stroked-button (click)="closeDialog(true)" [disabled]="!canEdit" [tabIndex]="-1">{{'GENERAL.OK'|translate}}</button>
  <button mat-stroked-button (click)="closeDialog(false)" [tabIndex]="-1">{{'GENERAL.CANCEL'|translate}}</button>
</div>