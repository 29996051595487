<div class="sub-panel-print" >
  <section class="header">
    <h4 class="bold-title">
      {{'GENERAL.NOTIFICATION'|translate}}
    </h4>
  </section>
  <mat-divider></mat-divider>
  <div class="sub-panel-markup-table">

  </div>
    <div class="content">
        <div class="col-name">
            <p class="title-toggle">
              {{"DIALOG.ERROR_MODEL.MESSAGE" | translate}}
              <br/> {{"DIALOG.ERROR_MODEL.MESSAGE_2" | translate}}
              <br/> {{"DIALOG.ERROR_MODEL.MESSAGE_3" | translate}}
            </p>
        </div>
    </div>
  <mat-divider></mat-divider>

  <div class="actions-dialog">
    <div class="right-div">
      <button mat-raised-button [mat-dialog-close]="false" (click)="handleCloseFile($event)" >
        {{'GENERAL.OK'|translate}}
      </button>
    </div>
  </div>
</div>

