<section class="mainDialog">
  <section class="cancel-div">
    <button mat-button [mat-dialog-close]="0" class="button-style">
      <mat-icon class="mat-svg-icon-custom" svgIcon="close" *ngIf="data.hasCloseIcon"></mat-icon>
    </button>
  </section>
  <section class="content">
    <div class="title-dialog">
      <h3 [innerHTML]="data.title" style="font-weight: bold"></h3>
    </div>
    <div class="notification-dialog" *ngIf="data.notification">
      <h4 [innerHTML]="data.notification"></h4>
    </div>
    <div class="message-dialog">
      <h4 [innerHTML]="data.message"></h4>
    </div>
    <section class="actions-dialog">
      <div class="yes-div">
        <button
          mat-raised-button
          [mat-dialog-close]="1"
          cdkFocusInitial
          color="primary"
        >
          {{data?.yesBtnContent}}
        </button>
      </div>
      <div class="no-div">
        <button mat-raised-button [mat-dialog-close]="2" cdkFocusInitial>
          {{data?.noBtnContent}}
        </button>
      </div>
    </section>
  </section>
</section>
