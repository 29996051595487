<section class="mainDialog">
  <div class="title-dialog">
    <h3 *ngIf="data.title !== ''" [innerHTML]="data.title"></h3>
    <h4 [innerHTML]="data.message"></h4>
    <h4 *ngIf="data.subMessage" [innerHTML]="data.subMessage" class="block-ellipsis" style="text-align: left; max-width: 95%;"></h4>
  </div>
  <div class="actions-dialog">
    <div *ngIf="!justOkAction" class="left-div">
      <!-- <button mat-raised-button [mat-dialog-close]="false">{{buttonCancel}}</button> -->
      <button
        mat-raised-button
        [mat-dialog-close]="true"
        cdkFocusInitial
        color="primary"
      >
        {{ buttonOk }}
      </button>
    </div>
    <div class="right-div">
      <button mat-raised-button [mat-dialog-close]="false">
        {{ buttonCancel }}
      </button>
      <!-- <button
        mat-raised-button
        [mat-dialog-close]="true"
        cdkFocusInitial
        color="primary"
      >
        {{ buttonOk }}
      </button> -->
    </div>
  </div>
</section>
