<mat-dialog-content
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragBoundary=".cdk-overlay-container"
>
  <div class="sub-panel-setting" id="container-panel-setting" #subpanelsetting>
    <div class="header" cdkDragHandle (click)="$event.stopPropagation()">
      <span class="left">{{ "CONTEXTMENU.TextSearch" | translate }}</span>
      <div class="right">
        <button
          mat-icon-button
          (click)="minimize()"
          *ngIf="!isHidde"
          [matTooltip]="'TOP_PANEL.TOP_RIGHT_PANEL.MINIMIZE' | translate"
        >
          <mat-icon>{{ "keyboard_arrow_up" }}</mat-icon>
        </button>
        <button
          mat-icon-button
          (click)="minimize()"
          *ngIf="isHidde"
          [matTooltip]="'TOP_PANEL.TOP_RIGHT_PANEL.MAXIMIZE' | translate"
        >
          <mat-icon>{{ "keyboard_arrow_down" }}</mat-icon>
        </button>
        <button
          mat-icon-button
          (click)="closeDialog()"
          matTooltip="{{ 'TOP_PANEL.TOP_RIGHT_PANEL.CLOSE' | translate }}"
        >
          <mat-icon>{{ "close" }}</mat-icon>
        </button>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-divider></mat-divider>
<section *ngIf="!isHidde" style="margin: 0 10px 10px 10px">
  <div class="search-container">
    <section
      class="form-input-search-conatiner"
      [class.form-focus]="isFocusInput"
    >
      <app-mat-icon-svg
        *ngIf="searchData.keysearch === ''"
        icon="searchmk"
        color="gray"
        size="medium"
      ></app-mat-icon-svg>
      <button
        mat-icon-button
        matTooltip="{{ 'SEARCH_FLOAT.CLEAR' | translate }}"
        class="button-clear"
        (click)="clearTextInput()"
        *ngIf="searchData.keysearch !== ''"
      >
        <app-mat-icon-svg
          icon="close"
          color="dark"
          size="medium"
        ></app-mat-icon-svg>
      </button>
      <input
        #inputSearch
        value="{{ searchData.keysearch }}"
        (focus)="focus()"
        (blur)="blur()"
        (input)="onInput($event)"
      />
      <div matTooltip="{{ 'SEARCH_FLOAT.MATCH_CASE' | translate }}">
        <button
          [class.btn-function-actived]="searchData.matchCase && !disabledMatchCase"
          class="btn-function-search"
          mat-icon-button
          (click)="toggleFunctionSearch('MATCH_CASE')"
          [disabled]="disabledMatchCase"
        >
          <span
            class="text-btn-match-case"
            [style.color]="!disabledMatchCase ? '#0D1C36' : '#AAB2BF'"
            >Aa</span
          >
        </button>
      </div>
      <div matTooltip="{{ 'SEARCH_FLOAT.MATCH_WHOLE_WORD' | translate }}">
        <button
          [class.btn-function-actived]="searchData.matchWholeWord && !disabledMatchWholeWord"
          class="btn-function-search"
          mat-icon-button
          (click)="toggleFunctionSearch('MATCH_WHOLE_WORD')"
          [disabled]="disabledMatchWholeWord"
        >
          <app-mat-icon-svg
            icon="whole-word"
            size="medium"
            [color]="!disabledMatchWholeWord ? 'dark' : 'gray'"
          ></app-mat-icon-svg>
        </button>
      </div>
    </section>
    <ng-container *ngIf="this.textSearch?.length > 0; else noResults">
      <span class="number-get-search"
        >{{ numberCurrentMatch }} of
        {{ this.textSearch.length | convertNumber }}</span
      >
    </ng-container>
    <ng-template #noResults>
      <span class="number-get-search">{{
        "SEARCH_FLOAT.NO_RESULTS" | translate
      }}</span>
    </ng-template>

    <div class="action-search">
      <div [matTooltip]="'SEARCH_FLOAT.NEXT_MATCH' | translate">
        <button
          mat-icon-button
          class="btn-next-match"
          [disabled]="
            this.textSearch?.length == numberCurrentMatch ||
            this.textSearch?.length < 2
          "
          (click)="nextMatch()"
        >
          <app-mat-icon-svg
            icon="down"
            [color]="
              this.textSearch?.length == numberCurrentMatch ||
              this.textSearch?.length < 2
                ? 'gray'
                : 'dark'
            "
            size="small"
          ></app-mat-icon-svg>
        </button>
      </div>

      <div [matTooltip]="'SEARCH_FLOAT.PREVIOUS_MATCH' | translate">
        <button
          mat-icon-button
          [disabled]="disablePre"
          (click)="previousMatch()"
          class="up-transform btn-previous-match"
        >
          <app-mat-icon-svg
            icon="down"
            [color]="disablePre ? 'gray' : 'dark'"
            size="small"
          ></app-mat-icon-svg>
        </button>
      </div>
    </div>
    <button mat-button (click)="search()">
      {{ "OPEN.SEARCH" | translate }}
    </button>
  </div>


  <ng-container *ngIf="this.textSearch?.length > 0; else noResultsSearch">
    <div [hidden]="isHidde" class="scrollable-list list-container">
      <ul #list>
        <li
          *ngFor="let item of textSearch"
          [class.active]="item.nodeId === currenNode"
          matTooltip="{{ item.text }}"
          id="search-item-{{ item.nodeId }}"
          (click)="selectionChange(item)"
          class="item"
        >
          <div mat-button class="limit-text-length">
            {{ item.text }}
          </div>
        </li>
      </ul>
    </div>
  </ng-container>
  <ng-template #noResultsSearch>
    <div [hidden]="isHidde" class="scrollable-list list-container noResults">
      <span>
        {{
          "SEARCH_FLOAT.NO_RESULTS" | translate
        }}
      </span>
    </div>
  </ng-template>
</section>
