/* eslint-disable no-console */
import { Observable, throwError } from 'rxjs';
import {
  HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse,
} from '@angular/common/http';
import { catchError, map, retry } from 'rxjs/operators';
import { UtilExtend } from 'src/app/core/utils/util-extend';
import { Router } from '@angular/router';

export class APIService {
  public isNeedIdentity = true;

  constructor(http: HttpClient, public router: Router) {
    this.http = http;
  }

  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  public BaseApi = '';

  private token: string = '';

  public http: HttpClient;

  updateToken(token: string) {
    this.token = token;
    this.httpOptions.headers = this.httpOptions.headers.set('Authorization', `Bearer ${this.token}`);
  }

  getToken() {
    return this.token;
  }

  static genBaseApi(protocol: string, host: string) {
    let baseApi = '';
    if (host.startsWith('http://') || host.startsWith('https://')) baseApi = `${host}`;
    else baseApi = `${protocol}://${host}`;
    return baseApi;
  }

  setBaseApi(protocol: string, host: string) {
    this.BaseApi = APIService.genBaseApi(protocol, host);
    // if (host.startsWith('http://') || host.startsWith('https://')) this.BaseApi = `${host}`;
    // else this.BaseApi = `${protocol}://${host}`;
  }

  get(uri: string): Observable<any> {
    return this.http.get(`${this.BaseApi}/${uri}`, this.httpOptions)
      .pipe(map(this.bodyRes), retry(2), catchError((err) => this.handleError(err)));
  }

  getFromUrl(url: string, header: string | { [name: string]: string | string[]; }): Observable<any> {
    return this.http.get(url, {
      headers: new HttpHeaders(header),
    }).pipe(map(this.bodyRes), retry(2), catchError((err) => this.handleError(err)));
  }

  getFile(uri: string): Observable<any> {
    return this.http
      .get(
        `${this.BaseApi}/${uri}`, { ...this.httpOptions, responseType: 'blob' as 'json' },
      );
  }

  getUrl(url: string) {
    return `${this.BaseApi}/${url}`;
  }

  getText(uri: string): Observable<any> {
    return this.http.get(`${this.BaseApi}/${uri}`, { responseType: 'text' })
      .pipe(catchError((err) => this.handleError(err)));
  }

  post(uri: string, data: any): Observable<any> {
    return this.http.post(`${this.BaseApi}/${uri}`, data, this.httpOptions)
      .pipe(map(this.bodyRes), retry(2), catchError((err) => this.handleError(err)));
  }

  postExt(strBaseApi: string, uri: string, data: any): Observable<any> {
    return this.http.post(`${strBaseApi}/${uri}`, data, this.httpOptions)
      .pipe(map(this.bodyRes), retry(2), catchError((err) => this.handleError(err)));
  }

  /**
   *
   * @param uri
   * @param data FormData
   * @returns
   */
  postBlob(uri: string, data: any): Observable<any> {
    return this.http.post(`${this.BaseApi}/${uri}`, data, { headers: { Accept: 'text/plain' }, responseType: 'text' })
      .pipe(catchError((err) => this.handleError(err)));
  }

  postExtend<T>(uri: string, data: any): Observable<T> {
    return this.http.post(`${this.BaseApi}/${uri}`, data, this.httpOptions)
      .pipe(map(this.bodyRes), retry(2), catchError((err) => this.handleError(err)));
  }

  postResText(uri: string, data: any, rt: number = 2): Observable<string> {
    return this.http.post(`${this.BaseApi}/${uri}`, data, { ...this.httpOptions, responseType: 'text' })
      .pipe(retry(rt < 1 ? 1 : rt), catchError((err) => this.handleError(err)));
  }

  postOnly(uri: string, data: any): Observable<string> {
    return this.http.post(`${this.BaseApi}/${uri}`, data, { ...this.httpOptions, responseType: 'text' });
  }

  put(uri: string, data: any): Observable<any> {
    return this.http.put(`${this.BaseApi}/${uri}`, data, this.httpOptions)
      .pipe(map(this.bodyRes), retry(2), catchError((err) => this.handleError(err)));
  }

  putExt(strBaseApi: string, uri: string, data: any): Observable<any> {
    return this.http.put(`${strBaseApi}/${uri}`, data, this.httpOptions)
      .pipe(map(this.bodyRes), retry(2), catchError((err) => this.handleError(err)));
  }

  putText(uri: string, data: any): Observable<any> {
    return this.http.put(`${this.BaseApi}/${uri}`, data, { ...this.httpOptions, responseType: 'text' })
      .pipe(retry(2), catchError((err) => this.handleError(err)));
  }

  delete(uri: string): Observable<any> {
    return this.http.delete(`${this.BaseApi}/${uri}`, this.httpOptions)
      .pipe(map(this.bodyRes), retry(2), catchError((err) => this.handleError(err)));
  }

  deleteResText(uri: string): Observable<any> {
    return this.http.delete(`${this.BaseApi}/${uri}`, {
      ...this.httpOptions,
      responseType: 'text',
    }).pipe(retry(1), catchError((err) => this.handleError(err)));
  }

  private bodyRes(res: HttpResponse<any>) {
    return res?.body ? { ...res.body } : res;
  }

  public handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    }
    if (error.message === 'timeout') {
      console.error(error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, `
        + `body was: ${error.error}`,
      );
      if (error.status === 401) {
        if (this.isNeedIdentity) this.returnLogin();
      }
    }
    return throwError(
      error.status ?? 'error',
    );
  }

  public returnLogin() {
    // console.clear();
    UtilExtend.clearUserStorage(false);
  }
}
