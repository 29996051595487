<div class="overlay-dialog">
  <section class="mainDialog mat-elevation-z8">
      <div class="title-dialog">
        <h1></h1>
      </div>
      
      <div class="title-dialog">
        <mat-form-field
          color="primary"
          appearance="outline">
              <mat-label>Password</mat-label>
              <input #box
                  matInput
                  [type]="hidePassword ? 'password' : 'text'"
                  placeholder="Password"
                  [value]="password"
                  >
              <mat-icon matSuffix color="primary" (click)="hidePassword = !hidePassword">vpn_key</mat-icon>
          </mat-form-field>
      </div>
      <mat-divider></mat-divider>
      <div class="actions-dialog">
        <div class="cancel-div">
          <button mat-raised-button
          (click)="cancelAction()">Cancel</button>
        </div>
        <div class="ok-div">
          <button mat-raised-button color="primary"
          (click)="okAction()">OK</button>
        </div>
      </div>
      <div class="title-dialog">
        
      </div>
  </section>
</div>
