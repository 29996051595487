/* eslint-disable no-param-reassign */
import { eOpenModel } from 'src/app/core/common/ad.setting.viewer3d';
import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject, Subscription } from 'rxjs';
import { MultiViewService } from 'src/app/core/services/multi-view.service';
import { Connect3dviewerService } from 'src/app/core/services/connect-3dviewer.service';
import { catchError, delay, filter, map, retryWhen, take } from 'rxjs/operators';
import { ViewerDisplayService } from 'src/app/core/services/viewer-display.service';
import { FileInfo, FileIdRev, ResponseModelFiles } from 'src/app/core/common/main-viewer-common';
import { FilesStatic } from 'src/app/core/class/FilesStatic';
import { StreamService } from 'src/app/core/services/api/stream.service';
import Util from 'src/app/core/utils/util';
import { UtilExtend } from 'src/app/core/utils/util-extend';
import { ConversionService } from 'src/app/core/services/api/conversion.service';
import { MarkupsUiService } from 'src/app/main-viewer/services/markups-ui.service';
import { WaterMarkConfig, Permission } from 'src/app/core/common/permission';
import { IExtEntity, IMarkupGroup, MarkupEntity } from 'src/app/core/common/markups';
import { LanguageManagerService } from 'src/app/main-viewer/services/language-manager.service';
import { DialogExtendService } from './dialog-extend.service';
import { SystemConstants } from '../../core/common/system.constants';
import { AdeptService } from '../../core/services/api/adept.service';
import { PermissionService } from '../../core/services/permission.service';
import {
  IPrintOption,
  IViewItemParameters,
  IPrintHeader,
  IViewParamsModel,
  IWatermark,
  EColorOption,
  ExtendParameters,
} from '../class/global';
import { PanelStatusService } from './panel-status.service';
import { CommonConstant } from 'src/app/core/constant/constant';
import { HttpClient } from '@angular/common/http';
import { PDFDocument } from 'pdf-lib';

export interface DataFileList {
  name: string;
  baseFileId: string;
  baseMajorRev: number;
  baseMinorRev: number;
  viewId: string;
  thumbnail?: any;
  data: FileInfo;
  checked?: boolean;
  viewIdChildren?: string[];
  markupCount?: number;
}

@Injectable({
  providedIn: 'root',
})
export class FilelistService {
  private fileList = new BehaviorSubject<FileInfo[]>([]); // chứa danh sách file parent

  public fileList$ = this.fileList.asObservable();

  public mapThumbnail = new Map<string, DataFileList>();

  public eventDragReleased = new EventEmitter<boolean>();

  public OriginFileListFromURL: FileInfo[] = [];

  public originFileList: FileInfo[] = []; // chứa tất cả các file

  public fileListOrigin: FileInfo[] = []; // Danh sach file nguyen ban khong filter (không bao gồm những file mới được thêm vào)

  public mapMergeChildParent = new Map<string, string>();

  public listPrintFile = new BehaviorSubject<FileInfo[]>([]);

  public listPrintFile$ = this.listPrintFile.asObservable();

  public isDataPrintReady = new BehaviorSubject<FileInfo>(null);

  printOption$ = new BehaviorSubject<IPrintOption>(null);

  public get printOption(): IPrintOption {
    return this.printOption$.value;
  }

  setPrintOption(o: IPrintOption) {
    o && this.printOption$.next(o);
  }

  public isDataPrintReady$ = this.isDataPrintReady.asObservable();

  public dragState = {
    idSource: '',
    canDrag: false,
  };

  openModel = eOpenModel.model;

  public isPrintProcessingObs = new BehaviorSubject<boolean>(false);

  private countChoosedFile: number = 0;

  public allowChangePermission = true;

  public listChecked: any[] = [];

  public disableClearAllCache = true;

  public arrConfiguration: string[] = []; // Contains a list of configuration names

  constructor(
    public multiService: MultiViewService,
    private viewerDisplayService: ViewerDisplayService,
    public connect3dViewerService: Connect3dviewerService,
    public adeptService: AdeptService,
    private permission: PermissionService,
    private dialogExtendService: DialogExtendService,
    public streamService: StreamService,
    public conversionService: ConversionService,
    private panelStatusService: PanelStatusService,
    private markupsUiService: MarkupsUiService,
    private languageManagerService: LanguageManagerService,
    private http: HttpClient

  ) {
    this.subscribeFileView();
  }

  async emitInitItems(value: FileInfo[]) {
    const newValue = this.multiService.mapItems(value);
    const mergeFile = await this.setFileList(newValue);
    this.OriginFileListFromURL = JSON.parse(JSON.stringify(value));
    this.multiService.initItems.next(mergeFile);
  }

  getFileList(): FileInfo[] {
    return this.fileList.value;
  }

  getOriginFileList(): FileInfo[] {
    return this.originFileList;
  }

  subscribeFileView() {
    this.viewerDisplayService.listViewDisplay$
      .pipe(filter((value) => !!value && value.length > 0))
      .subscribe((list) => {
        this.fileView = list;
      });
  }

  async setFileList(lists: FileInfo[]): Promise<FileInfo[]> {
    const currFileList = this.getFileList();
    const mergeFiles = this.mergeFileInfo(lists);
    let result = null;
    if (mergeFiles) result = [...currFileList, ...mergeFiles];
    else result = [...currFileList];
    let showWaiting = true;
    setTimeout(() => {
      this.multiService.setWaiting(showWaiting);
    }, SystemConstants.TIME_DELAY_TO_SHOW_WAITING);

    result?.length > 0 && await this.checkCacheFileInfo(result[0]);
    showWaiting = false;
    this.multiService.setWaiting(showWaiting);
    this.nextFileList(result);
    return result;
  }

  nextFileList(lists: FileInfo[]) {
    this.fileList.next(lists);
  }

  /**
   *
   * @param fileId : viewId
   */
  getFileInfo(fileId: string): FileInfo {
    return this.fileView.find((item) => item.viewId === fileId);
  }

  getFileInfoByFileId(fileId: string): FileInfo {
    return this.fileView.find((item) => item.id === fileId);
  }

  addFileToDisplay(fileDrag: DataFileList, idTarget: string) {
    if (this.fileView.length > 0) {
      const indexView = this.fileView.findIndex(
        (value) => value.viewId === idTarget
      );
      this.replaceFileView(indexView, fileDrag.data);
    }
  }

  checkModeMarkup(viewId: string): boolean {
    return this.multiService.checkModeMarkup(viewId);
  }

  setModeMarkupNochange(viewId: string) {
    this.multiService.setModeMarkupNochange(viewId);
  }

  replaceFileView(index: number, fileInfo: FileInfo): void {
    const newFileInfo = this.multiService.replaceFileView(index, fileInfo);
    this.eventDragReleased.emit(true);
  }

  checkExistInListDropdown(fileInfo: FileInfo) {
    return this.fileList.value.findIndex(
      (file) => file.viewId === fileInfo.viewId
    );
  }

  addFileExistedToListDropdown(fileInfo: FileInfo) {
    const check = this.checkExistInListDropdown(fileInfo);
    if (check === -1) {
      const arrFileList = this.fileList.value;
      arrFileList.push(fileInfo);
      this.nextFileList(arrFileList);
    }
  }

  addFileList(newArr: FileInfo[], loadView: boolean = true) {
    const currFileList = this.getFileList();

    let tempAdd = newArr.reduce((re, curr) => {
      if (currFileList && currFileList.length > 0) {
        !currFileList.some((file) => UtilExtend.sameFileIdRev(file, curr)) && re.push(curr);
      } else {
        re.push(curr);
      }
      return re;
    }, []);

    if (tempAdd.length > 0) {
      tempAdd = this.multiService.mapItems(tempAdd);
      loadView && this.multiService.loadFileIntoBlankView(tempAdd);
      const mergeFiles = this.mergeFileInfo(tempAdd);
      const result = [...currFileList, ...mergeFiles];
      this.nextFileList(result);
    }
  }

  removeItemInListDropdown(item: FileInfo) {
    const listFileDropdown = this.getFileList();
    const index = listFileDropdown.findIndex(
      (i) =>
        (i.viewIdChildren && i.viewIdChildren.includes(item.viewId))
        || i.viewId === item.viewId
        || UtilExtend.sameFileIdRev(i, item),
    );
    if (index >= 0) {
      const files = this.fileList.value;
      const temp = files.filter((i) => !UtilExtend.sameFileIdRev(i, item));
      this.nextFileList(temp);
      this.multiService.closeItemExt(item);
      listFileDropdown.splice(index, 1);
      this.setFileList(listFileDropdown).then(() => {
        try { // delete originFileList & mapMerge
          const vId = this.getViewId(item.viewId);
          if (vId) {
            const listVID = this.mapMergeParentChild.get(vId);
            if (listVID) {
              this.mapMergeParentChild.delete(vId);
              listVID.forEach((id) => {
                this.mapMergeChildParent.delete(id);
              });
            }
          }
          this.originFileList = this.originFileList.filter((i) => !UtilExtend.sameFileIdRev(i, item));
        } catch {
          //
        }
      });
    }
  }

  // removeItem(item: DataFileList, event: MouseEvent) {
  //   const indexInListDisplay = this.multiService.getIndexFileSelected(item.data);
  //   if (indexInListDisplay !== -1) {
  //     if (this.checkModeMarkup(item.viewId)) {
  //       const dialog = this.dialogExtendService.closeWarning(item.name);
  //       dialog.afterClosed().subscribe((value) => {
  //         if (value && value === 1) {
  //           setTimeout(() => {
  //             const baseViewId = this.getViewId(item.viewId);
  //             this.multiService.saveMarkup(baseViewId).then(() => {
  //               this.multiService.closeItemEx(item.data, indexInListDisplay);
  //               this.removeItemInListDropdown(item.data);
  //             });
  //           }, SystemConstants.TIME_DELAY_DIALOG_CLOSE);
  //         }
  //       });
  //     } else {
  //       this.multiService.closeItemEx(item.data, indexInListDisplay);
  //       this.removeItemInListDropdown(item.data);
  //     }
  //   } else {
  //     this.removeItemInListDropdown(item.data);
  //   }
  // }

  checkMarkupModeAllCopy(viewsId: string[]) {
    return viewsId.some((id: string) => this.checkModeMarkup(id));
  }

  setMarkupModeNoChange(viewsId: string[]) {
    viewsId.some((id: string) => this.setModeMarkupNochange(id));
  }

  removeAllItemInListView(arrFileInfo: FileInfo[], itemDropdown: DataFileList, state:boolean) {
    arrFileInfo.forEach((fileInfo) => {
      const indexFileRemove = this.multiService.getIndexFileSelected(fileInfo);
      // const baseViewId = this.getViewId(fileInfo.viewId);
      // if (baseViewId === fileInfo.viewId)
      if (indexFileRemove >= 0) this.multiService.closeItemEx(fileInfo, indexFileRemove, null, true, null, state);
    });
    this.removeItemInListDropdown(itemDropdown.data);
  }

  async removeItemAndAllCopy(
    item: DataFileList,
    event: MouseEvent,
    checkClose: Subject<boolean> = null,
    showPopup = true,
    state? : boolean,
  ) {
    const arrFileInfo = this.multiService.getAllCopyFile(item);

    if (arrFileInfo?.length > 0) {
      const arrViewsId = arrFileInfo.map((file) => file.viewId);
      if (this.checkMarkupModeAllCopy(arrViewsId) && showPopup) {
        const fileInfo = item?.data;
        const dialog = this.dialogExtendService.closeWarning(
          fileInfo?.sourceFile || fileInfo?.originalFile || fileInfo?.filename
        );
        const diagResp = await dialog.afterClosed().toPromise();
        if (diagResp && diagResp === 1) {
          // this.setMarkupModeNoChange(arrViewsId);

          await of(true)
            .pipe(delay(SystemConstants.TIME_DELAY_DIALOG_CLOSE))
            .toPromise();
          const baseViewId = this.getViewId(item.viewId);

          await this.multiService.saveMarkup(baseViewId);

          this.removeAllItemInListView(arrFileInfo, item, state);
          checkClose && checkClose.next(true);
        } else {
          checkClose && checkClose.next(false);
        }
      } else {
        this.removeAllItemInListView(arrFileInfo, item, state);
        checkClose && checkClose.next(true);
      }
    } else {
      this.removeItemInListDropdown(item.data);
      checkClose && checkClose.next(true);
    }
  }

  removeAllItemAndAllCopy(
    listItems: FileInfo[],
    event: any,
    showPopup = false,
    state? : boolean,
  ) {
    const listItemCount = listItems.length;
    return new Promise((resolve, reject) => {
      if (listItems?.length) {
        let count = 0;
        const checkSave = new Subject<boolean>();
        const obserCheckSave = checkSave.asObservable();
        obserCheckSave.subscribe((v) => {
          count += 1;
          if (count >= listItemCount) {
            setTimeout(() => {
              resolve(true);
            }, 500);
          }
        });

        const listOfItems = JSON.parse(JSON.stringify(listItems));

        listOfItems.forEach((item) => {
          this.removeItemAndAllCopy(
            {
              name: item.filename,
              baseFileId: item.baseFileId,
              baseMajorRev: item.baseMajorVersion ? item.baseMajorVersion : item.baseMajorRev,
              baseMinorRev: item.baseMinorVersion ? item.baseMinorVersion : item.baseMinorRev,
              viewId: item.viewId,
              data: item,
            },
            event,
            checkSave,
            showPopup,
            state
          );
        });
      } else resolve(false);
    });
  }

  /** Merger */
  getFileInfoFromModelFileId(modelFileId: string): FileInfo {
    return this.originFileList.find((f) => f.modelFileId === modelFileId);
  }

  checkFileVersion(f: FileInfo, ext: IExtEntity, isCopy: boolean) {
    // return (
    //   f.baseFileId === ext.extBaseFileId &&
    //   f.baseMajorRev === ext.extBaseMajorRev &&
    //   f.baseMinorRev === ext.extBaseMinorRev
    // );
    // is copy file => ignore check baseFileId
    return f.baseFileId === ext.extBaseFileId || isCopy; // Don't check baseMajorRev and baseMinorRev
  }

  getFileInfoFromAddModelFileId(ext: IExtEntity, modelFiles: [ResponseModelFiles], fileName: string, isCopy: boolean): FileInfo {
    if (!ext) return null;
    return modelFiles && modelFiles.find((f) => {
      const isVer = this.checkFileVersion(f, ext, isCopy);
      if (fileName === f.filename) return isVer; // Don't check rootModel
      return isVer && (f.filename === ext.extModelFileId);
    });
  }

  completeDrag() {
    this.dragState.idSource = '';
    this.dragState.canDrag = false;
  }

  /**
   * return baseViewId
   * @param modelFileId
   */
  getViewIdFromModelFileId(modelFileId: string): string {
    const fileInfo = this.getFileInfoFromModelFileId(modelFileId);
    return fileInfo?.viewId ?? null;
  }

  getModelFileIdFromViewId(viewId: string): string {
    const currViewId = this.getViewId(viewId);
    const fileInfo = this.originFileList.find((f) => f.viewId === currViewId);
    return fileInfo?.modelFileId ?? null;
  }

  /**
   * kiem tra file nay da co cache chua
   * @param modelFileId
   */
  async checkCacheFileInfo(fileInfor: FileInfo) {
    const ret = this.conversionService.getCacheByModelId(fileInfor.modelFileId).toPromise();
    return ret;
  }

  loadFileInfo(modelFileId: string) {
    const fileInfoTarget = this.getFileInfoFromModelFileId(modelFileId);
    if (fileInfoTarget) {
      return this.checkCacheFileInfo(fileInfoTarget).then((v) => {
        if (v) {
          const listAllMergerFile = this.getAllFileInfoOfFileMerged(fileInfoTarget);
          if (listAllMergerFile) {
            this.multiService.loadFileMerged(fileInfoTarget, listAllMergerFile);
          }
        }
        return v;
      });
    }
    return false;
  }

  getAllFileInfoOfFileMerged(fileInfo: FileInfo = this.multiService.viewActive.data): FileInfo[] {
    const viewid = fileInfo.viewId.includes('view-only') ? fileInfo.viewIdParent : fileInfo.viewId;
    // const viewid = fileInfo.viewId;
    const viewIdParent = fileInfo.viewIdChildren
      ? viewid
      : this.mapMergeChildParent.get(viewid);
    if (viewIdParent) {
      const item = this.fileList.value.find((f) => f.viewId === viewIdParent);
      if (item && item.viewIdChildren) {
        return this.originFileList.filter((i) =>
          item.viewIdChildren.includes(i.viewId)
        );
      }
    }
    return null;
  }

  getInfoMerger(viewId: string): {
    parentId: string;
    childrenId: string[];
    fileIdRev: FileIdRev;
  } {
    const viewParentId = this.mapMergeChildParent.get(viewId);
    if (viewParentId) {
      const viewIdChildren = this.mapMergeParentChild.get(viewParentId);
      const fileInfo = this.fileList.value.find(
        (_) => _.viewId === viewParentId
      );
      if (fileInfo && viewIdChildren) {
        const fileIdRev: FileIdRev = {
          baseFileId: fileInfo.baseFileId,
          baseMajorRev: fileInfo.baseMajorRev,
          baseMinorRev: fileInfo.baseMinorRev,
        };
        return {
          parentId: viewParentId,
          childrenId: viewIdChildren,
          fileIdRev,
        };
      }
    }
    return null;
  }

  getAllFileInfoRelated(fileIdRev: FileIdRev): FileInfo[] {
    return this.originFileList.filter((v) => UtilExtend.sameFileIdRev(v, fileIdRev));
  }

  getFileIdRev(viewId: string): FileIdRev {
    const fileInfo = this.originFileList.find((v) => v.viewId === viewId);
    if (fileInfo) {
      return {
        baseFileId: fileInfo.baseFileId,
        baseMajorRev: fileInfo.baseMajorRev,
        baseMinorRev: fileInfo.baseMinorRev,
      };
    }
    return null;
  }

  /**
   * baseViewId
   * @param viewId
   */
  getViewId(viewId: string): string {
    const viewIdParent = this.mapMergeChildParent.get(viewId);
    return viewIdParent ?? viewId;
  }

  /**
   * baseFileId
   * @param viewId
   */
  getFileId(viewId: string): string {
    const file = this.getFileIdRev(viewId);
    if (file) return UtilExtend.getIdFileIdRev(file);
    return null;
  }

  getChilds(parentId: string) {
    return this.mapMergeParentChild.get(parentId) ?? [];
  }

  getParent(parentId: string): FileInfo {
    return this.fileList.value.find((f) => f.viewIdParent === parentId);
  }

  /** private */
  private groupByBaseFileIdDWG(lists: FileInfo[]): {
    files: FileInfo[];
    mapId: string[];
  } {
    const map = [];
    let fileLoad = lists[0];
    let getItem: FileInfo = null;
    let temp = null;
    if (fileLoad) {
      const sheet = fileLoad.extraConvert?.Sheets?.filter(
        (vv) => vv.isActive === true
      );
      if (sheet && sheet.length > 0) {
        if (sheet[0].name === '2D Model') {
          if (this.openModel.toString() === eOpenModel.model.toString()) {
            temp = lists.find((f) => f.cacheFilename === null);
          } else {
            temp = lists.find((f) => f.cacheFilename === '2D Model');
          }
        } else {
          // sheet thong thuong
          temp = lists.find((f) => f.cacheFilename === sheet[0].name);
        }
      } else if (
        fileLoad.cacheFilename === null &&
        this.openModel.toString() === eOpenModel.model2D.toString()
      ) {
        temp = lists.find((f) => f.cacheFilename === '2D Model');
      }

      fileLoad = temp || fileLoad;
      const firstFile = fileLoad; // điêu chinh lai file active dau tien
      if (
        this.openModel.toString() === eOpenModel.model2D.toString() ||
        (this.openModel.toString() === eOpenModel.model.toString() && !temp)
      ) {
        const sheetActive = firstFile.extraConvert?.Sheets?.filter(
          (vv) => vv.isActive === true
        );
        const id =
          sheetActive && sheetActive.length > 0 ? sheetActive[0].id : null;
        const listFileSameBase = lists.filter((item) => UtilExtend.sameFileIdRev(item, firstFile));
        if (id) {
          listFileSameBase.forEach((v) => {
            if (v.filename === `${id}`) fileLoad = v;
          });
        }
      }
      getItem = {
        ...firstFile,
        groupByBaseFileId: [fileLoad.modelFileId],
        viewIdChildren: [fileLoad.viewId],
        viewIdParent: fileLoad.viewId,
      };
      fileLoad.viewIdParent = fileLoad.viewId;
      this.mapMergeChildParent.set(fileLoad.viewId, getItem.viewId);
      this.mapMergeParentChild.set(getItem.viewId, [fileLoad.viewId]);
    }
    const re = lists.reduce((pre, file) => {
      const listFileSameBase = lists.filter((item) => UtilExtend.sameFileIdRev(item, file));
      const lengthFileSameBaseFileId = listFileSameBase.length;
      if (lengthFileSameBaseFileId > 1 || file.multiStream) {
        map.push(file.modelFileId);
        if (getItem && pre.length === 0) {
          pre.push(getItem);
        }
        if (fileLoad.modelFileId === file.modelFileId) {
          return pre;
        }

        const inPre = pre.find((i) => {
          if (i) {
            const b = UtilExtend.sameFileIdRev(i, file);
            return b;
          }
          return false;
        });
        if (inPre) {
          inPre.groupByBaseFileId.push(file.modelFileId);
          inPre.viewIdChildren.push(file.viewId);
          file.viewIdParent = inPre.viewId;
          this.mapMergeChildParent.set(file.viewId, inPre.viewId);
          this.mapMergeParentChild.set(inPre.viewId, inPre.viewIdChildren);
        } else {
          let firstFile = file;
          const sheetActive = file.extraConvert?.Sheets?.filter(
            (vv) => vv.isActive === true
          );
          const id =
            sheetActive && sheetActive.length > 0 ? sheetActive[0].id : null;
          if (id) {
            listFileSameBase.forEach((v) => {
              if (v.filename === `${id}`) firstFile = v;
            });
          }
          getItem = {
            ...firstFile,
            groupByBaseFileId: [file.modelFileId],
            viewIdChildren: [file.viewId],
            viewIdParent: file.viewId,
          };
          pre.push(getItem);
          file.viewIdParent = file.viewId;
          this.mapMergeChildParent.set(file.viewId, getItem.viewId);
          this.mapMergeParentChild.set(getItem.viewId, [file.viewId]);
        }
      }
      return pre;
    }, []);
    FilesStatic.mapMergeChildParent = this.mapMergeChildParent;
    FilesStatic.mapMergeParentChild = this.mapMergeParentChild;
    return { files: re, mapId: map };
  }

  private groupByBaseFileId(lists: FileInfo[]): {
    files: FileInfo[];
    mapId: string[];
  } {
    const map = [];
    const re = lists.reduce((pre, file) => {
      const listFileSameBase = lists.filter((item) => UtilExtend.sameFileIdRev(item, file));

      const lengthFileSameBaseFileId = listFileSameBase.length;
      if (lengthFileSameBaseFileId > 1 || file.multiStream) {
        map.push(file.modelFileId);
        const inPre = pre.find((i) => {
          if (i) {
            return UtilExtend.sameFileIdRev(i, file);
          }
          return false;
        });
        if (!inPre) {
          let firstFile = file; // điêu chinh lai file active dau tien
          // eslint-disable-next-line no-unneeded-ternary
          const isPrint = this.multiService.printCreatorParam ? true : false;
          if (!isPrint) {
            const sheetActive = file.extraConvert?.Sheets?.filter(
              (vv) => vv.isActive === true
            );
            const id = 
              sheetActive && sheetActive.length > 0 ? (sheetActive[0].id ?? sheetActive[0].outputFileName) : null; // outputFileName: idw, slddrw
            if (id) {
              listFileSameBase.forEach((v) => {
                if (v.filename === `${id}`) {
                  this.swapFileByViewId(lists, v, file); // Thay đổi firstFile nên phải update lại lists và map
                  const index = map.findIndex((m) => m === file.modelFileId);
                  if (index !== -1) map[index] = v.modelFileId;
                  firstFile = v;
                  file = v;
                }
              });
            }
          }
          const getItem: FileInfo = {
            ...firstFile,
            groupByBaseFileId: [file.modelFileId],
            viewIdChildren: [file.viewId],
            viewIdParent: file.viewId,
          };
          pre.push(getItem);
          file.viewIdParent = file.viewId;
          this.mapMergeChildParent.set(file.viewId, getItem.viewId);
          this.mapMergeParentChild.set(getItem.viewId, [file.viewId]);
        } else {
          inPre.groupByBaseFileId.push(file.modelFileId);
          inPre.viewIdChildren.push(file.viewId);
          file.viewIdParent = inPre.viewId;
          this.mapMergeChildParent.set(file.viewId, inPre.viewId);
          this.mapMergeParentChild.set(inPre.viewId, inPre.viewIdChildren);
        }
      }
      return pre;
    }, []);

    FilesStatic.mapMergeChildParent = this.mapMergeChildParent;
    FilesStatic.mapMergeParentChild = this.mapMergeParentChild;
    return { files: re, mapId: map };
  }

  private swapFileByViewId(listFile: FileInfo[], file1: FileInfo, file2: FileInfo) {
    const index1 = listFile.findIndex((f) => f.viewId === file1.viewId);
    const index2 = listFile.findIndex((f) => f.viewId === file2.viewId);
    listFile[index1] = file2;
    listFile[index2] = file1;
  }

  private mergeFileInfo(listFiles: FileInfo[]): FileInfo[] {
    const listIdMerged = this.originFileList.map((f) => f.viewId);
    const listFilesNotMerge = listFiles.filter((f) => !listIdMerged.includes(f.viewId));
    if (listFilesNotMerge && listFilesNotMerge.length > 0) {
      // chi merge nhung file chua merge
      this.originFileList = [...this.originFileList, ...listFilesNotMerge];
      const file = listFilesNotMerge[0];
      const { originalFile } = file;
      const { length } = originalFile;
      const from = length >= 3 ? length - 3 : 0;
      let result = null;
      if (originalFile.substr(from).toLowerCase() === 'dwg' && !this.multiService.isDirect) {
        result = this.groupByBaseFileIdDWG(listFilesNotMerge);
      } else {
        result = this.groupByBaseFileId(listFilesNotMerge);
      }
      const { files, mapId } = result;
      const filterSame = listFiles.filter((i) => {
        return !mapId.includes(i.modelFileId);
      });

      const newList = [...files, ...filterSame];
      this.multiService.mapMergeParentChild = this.mapMergeParentChild;
      return newList;
    }
    return null;
  }

  updateExtraData(listFile: FileInfo[]) {
    if (listFile && listFile.length > 0) {
      // const groupBy = (xs, keys) => xs.reduce((rv, x) => {
      //   let gId = '';
      //   keys.forEach((field) => {
      //     if (gId === '') gId = `${x[field]}`;
      //     else gId += `.${x[field]}`;
      //   });
      //   (rv[gId] = rv[gId] || []).push(x);
      //   return rv;
      // }, {});

      const groupByBaseFileId = UtilExtend.groupBy(listFile, ['baseFileId', 'baseMajorRev', 'baseMinorRev']);
      if (groupByBaseFileId) {
        const keys = Object.keys(groupByBaseFileId);
        keys &&
          keys.forEach((x) => {
            const listFilebyGroup = groupByBaseFileId[x];
            let list1 = listFilebyGroup.filter(
              (f: FileInfo) => f.isRootModel === 1
            );
            if (!list1 || list1.length < 1)
              list1 = listFilebyGroup.filter(
                (f: FileInfo) => f.isRootModel === -1
              );
            let rootElement = null;
            // eslint-disable-next-line prefer-destructuring
            if (list1 && list1.length > 0) rootElement = list1[0];
            // eslint-disable-next-line prefer-destructuring
            else rootElement = listFilebyGroup[0];

            const list2 = listFilebyGroup.filter(
              (f: FileInfo) => f.isRootModel >= 0
            );
            const firstElement = list2 && list2[0];

            // eslint-disable-next-line max-len
            let imageLocation = `${
              rootElement.streamLocation
            }/${rootElement.filename.substring(
              0,
              rootElement.filename.lastIndexOf('.'),
            )}.png`;

            // const url = this.streamService.getUrlFromLocation(imageLocation);
            const urlExist = this.streamService.checkPathExist({ path: imageLocation });

            if (!urlExist) {
              // eslint-disable-next-line max-len
              imageLocation = `${
                firstElement.streamLocation
              }/${firstElement.filename.substring(
                0,
                firstElement.filename.lastIndexOf('.'),
              )}.png`;
            }
            // Push a configuration as a file to listFilebyGroup
            this.updateListFileConfiguration(listFilebyGroup, this.multiService.isDirect);
            listFilebyGroup.forEach((element: FileInfo, index: number) => {
              // eslint-disable-next-line no-param-reassign
              if (element.extraConvertOutput)
                element.extraConvert = JSON.parse(element.extraConvertOutput);
              // eslint-disable-next-line no-param-reassign
              element.thumbnailLocation = imageLocation;
              if (this.multiService.isDirect && element.converter === SystemConstants.VIEWER_MODE.HOOP) {
                if (element.filename.lastIndexOf('.') !== -1) {
                  element.filename = `${element.filename.substring(0, element.filename.lastIndexOf('.'))}.pdf`;
                } else {
                  element.filename = `${element.filename}.pdf`;
                }
                element.converter = 'Foxit';
                element.isDirect = true;
                // Push an edited configuration to listFile
                if (index !== 0 && this.arrConfiguration.length > 0) listFile.push(element);
              }
            });
          });
      }
    }
  }

  getRootFile(fileIdRev: FileIdRev): FileInfo {
    if (this.fileListOrigin) {
      return this.fileListOrigin.find(
        (file) => UtilExtend.sameFileIdRev(file, fileIdRev) && file.isRootModel < 0,
      );
    }
    return null;
  }

  private fileView: FileInfo[] = [];

  private mapMergeParentChild = new Map<string, string[]>();

  getFileName(
    modelFileId: string,
    page: number = null,
    sheetId: number = null
  ) {
    let result = null;
    let file = this.fileListOrigin.find((f) => f.modelFileId === modelFileId);
    if (!file) {
      file = this.originFileList.find((f) => f.modelFileId === modelFileId);
    }
    if (file) {
      const modelName = `${UtilExtend.nameSheet(
        file.filename,
        file.originalFile
      )}`;
      const filename = Util.removeFileExt(file.filename);
      let cacheFilename = Util.removeFileExt(file.cacheFilename);
      if (!cacheFilename) {
        cacheFilename = modelName === filename ? 'Model' : modelName;
      }
      result = {
        ModelFileId: file.modelFileId,
        fileName: cacheFilename,
        page,
        sheetId,
        listMarkup: [],
        isShow: true,
      };
    }
    return result;
  }

  hidePrintBtn(showHide: boolean, isPrintProcessingObs?: BehaviorSubject<boolean>) {
    isPrintProcessingObs ? isPrintProcessingObs.next(showHide) : this.isPrintProcessingObs.next(showHide);
  }

  async exportAndGetPdfFile(printOption: IPrintOption, listChecked?: FileInfo[], isPrintAll?: boolean) {
    const timePdfExportStart = Date.now();
    Util.logTimeProcess(0, 'PDF_EXPORT Start');
    if (listChecked) {
      this.listChecked = listChecked;
    }
    const listPrintParam = [];
    for (let i = 0; i < this.listChecked.length; i += 1) {
      // eslint-disable-next-line no-await-in-loop
      const printparam = await this.createPrintParameter(
        printOption,
        this.listChecked[i],
        false,
        isPrintAll,
      );
      listPrintParam.push(printparam);
    }
    const data = JSON.stringify(listPrintParam);

    const exportDirect = SystemConstants.PDF_EXPORT.EXP_DIRECTLY;
    const export3dAs2d = SystemConstants.PDF_EXPORT.EXP_3DAS2D;
    const overwrite = true;
    const outputPngResolution = null;
    const serverSideConvert = listPrintParam[0].viewItemParameters.serverSideConvert ? true : false;
    let conversionService: any;
      
    if (serverSideConvert) {
      conversionService = this.adeptService;
    }
    else {
      conversionService = this.conversionService;
    }
    const data1 = await conversionService.postPrintParamNew(data,
      exportDirect, export3dAs2d, overwrite, outputPngResolution).toPromise();

    Util.logTimeProcess(timePdfExportStart, 'PDF_EXPORT End');

    const res = JSON.parse(data1);
    // res : [ "C:\\abc\\file.pdf",]
    const item = res[0];
    const encodeURI = encodeURIComponent(item);
    const downloadServer = serverSideConvert ? `${new URL(this.adeptService.BaseApi).origin}/streaming` : `${this.streamService.BaseApi}`;
    const url = `${downloadServer}/api/download?path=${encodeURI}`;
    // serverSideConvert = true => url = "http://adept.anybim.vn/Synergis.WebApi/streaming/C:\\abc\\file.pdf"
    // serverSideConvert = false => url = "localhost:11182/api/download?path=C:\\abc\\file.pdf"
    const urlExist = serverSideConvert ? this.streamService.checkPathExist({ path: item }, downloadServer) : this.streamService.checkPathExist({ path: item });
    // Check if the file exists on the server { path: "C:\\abc\\file.pdf"}
    if (urlExist) {
      return { printParams: listPrintParam[0], url: url };
    }
    else {
      return { printParams: listPrintParam[0], url: null };
    }
  }

  saveFile(path: string, serverSideConvert: boolean = false) {
    // [27042024] [ADV-7569] [phuong_td] add parameters serverSideConvert and Comment the code to get serverSideConvert from file info
    // const serverSideConvert = this.multiService.viewActive.data.serverSideConvert ? true : false;
    const encodeURI = encodeURIComponent(path);
    const downloadServer = serverSideConvert ? `${new URL(this.adeptService.BaseApi).origin}/streaming` : `${this.streamService.BaseApi}`;
    const url = `${downloadServer}/api/download?path=${encodeURI}`;
    const urlExist = this.streamService.checkPathExist({ path }, downloadServer);
    if (urlExist) {
      window.open(
        url,
        '',
      );
    } else {
      console.log(`File does not exist \nurl: ${url}`);
    }
  }

  checkserverSideConvert(file: FileInfo) {
    const fileId = UtilExtend.getIdFileIdRev(this.multiService.viewActive.data);
    const adeptInfor = this.permission.getUser(fileId); // get session infor
    return !!adeptInfor.viewItemParameters.serverSideConvert;
  }

  async exportPdfFiles(
    printOption: IPrintOption,
    listChecked?: FileInfo[],
    isSelectFile: boolean = false,
    isPrintProcessingObs?: BehaviorSubject<boolean>,
    // tslint:disable-next-line: align
    countChoosedFile?: number,
    allowChangePermission?: boolean,
    disableClearAllCache?: boolean,
    isAllFile?: boolean,
  ) {
    const timePdfExportStart = Date.now();
    Util.logTimeProcess(0, 'PDF_EXPORT Start');
    let subPrintResult: Subscription = null; // subcription waiting print result
    if (listChecked) {
      this.listChecked = listChecked;
    }
    let isStillWaitingDialog = true;
    const dialog = this.dialogExtendService.startWaiting('message');
    dialog.afterClosed().pipe(take(1)).subscribe((ret) => {
      if (ret === -1) { // force close
        isStillWaitingDialog = false;
        subPrintResult && subPrintResult.unsubscribe(); // stop getResult on client but can't stop the process on server :(
        this.hidePrintBtn(false, isPrintProcessingObs);
      }
      // close normal
    });
    this.hidePrintBtn(true, isPrintProcessingObs);
    countChoosedFile !== null
      ? (countChoosedFile = 0)
      : (this.countChoosedFile = 0);
    allowChangePermission !== null
      ? (allowChangePermission = false)
      : (this.allowChangePermission = false);
    const listPrintParam = [];
    for (let i = 0; i < this.listChecked.length; i += 1) {
      if (isStillWaitingDialog) {
        // eslint-disable-next-line no-await-in-loop
        const printparam = await this.createPrintParameter(
          printOption,
          this.listChecked[i],
          isSelectFile,
        );
        listPrintParam.push(printparam);
      }
    }

    const data = JSON.stringify(listPrintParam);

    if (isStillWaitingDialog) {
      const exportDirect = SystemConstants.PDF_EXPORT.EXP_DIRECTLY;
      const export3dAs2d = SystemConstants.PDF_EXPORT.EXP_3DAS2D;
      const overwrite = true;
      const outputPngResolution = null;
      const serverSideConvert = listPrintParam[0].viewItemParameters.serverSideConvert ? true : false;
      let conversionService: any;

      if (serverSideConvert) {
        conversionService = this.adeptService;
      }
      else {
        conversionService = this.conversionService;
      }

      subPrintResult = conversionService.postPrintParamNew(data,
        exportDirect, export3dAs2d, overwrite, outputPngResolution).subscribe((data1) => { // postPrintParam
        if (isStillWaitingDialog) {
          isStillWaitingDialog = false;
          let isOk = false;
          if (data1 != null) {
            Util.logTimeProcess(timePdfExportStart, 'PDF_EXPORT merging');
            const listpdfFile = JSON.parse(data1);
            isOk = true;
            // MergePdfFile
            this.conversionService.mergePdfFile(listpdfFile).subscribe((item) => { // item: path
              Util.logTimeProcess(timePdfExportStart, 'PDF_EXPORT End');
              // John Lee _ 09/04/2024 _ Returns the original value for WatermarkHeaderConfig after printing
              this.permission.updateWatermarkHeaderConfigPrint(this.permission.getWaterMarkFile());
              if (item) {
                const file = item[0];
                if (isAllFile) {
                  this.streamService.downloadFile({ path: file }).subscribe(async (response: BodyInit) => {
                    const buffer = await new Response(response).arrayBuffer(); // https://javascript.info/blob
                    const blob = new window.Blob([buffer], { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(blob);
                    const w = window.open(fileURL);
                    w.print();
                  });
                } else {
                  this.saveFile(file, serverSideConvert);
                }
              } else {
                console.log('Error: file merge failed');
              }
              this.dialogExtendService.closeCurrentDialog();
            });
          } else {
            this.dialogExtendService.closeCurrentDialog();
          }
          if (!isOk) {
            const translations = this.languageManagerService.getCurentTranslations();
            const messages = `${translations.DIALOG.PRINT.EXPORT_FAILED}.`;
            this.dialogExtendService.showNotification(
              '',
              messages,
              false,
              430,
              translations.GENERAL.OK,
            );
          }
        } // service called: ignore result although it has
      });
      this.hidePrintBtn(false, isPrintProcessingObs);
      // const timerId = setInterval(() => { // co tinh call nhieu lan sau 10s
      //   if (isStillWaitingDialog) {
      //     subPrintResult = this.conversionService.postPrintParamNew(data, exportDirect, export3dAs2d,
      //       overwrite, outputPngResolution).subscribe();
      //   } else clearTimeout(timerId);
      // }, 10000);
    } else { // good: no call service
      this.hidePrintBtn(false, isPrintProcessingObs);
    }
    // if (listPrintParam && listPrintParam.length > 0) {
    //   const firstParam = listPrintParam[0]; // for test
    //   // eslint-disable-next-line prefer-destructuring
    //   const printRapam = JSON.stringify(firstParam);
    //   localStorage.setItem(Util.PRINT_PARAM_KEY, printRapam);
    //   window.open(`http://localhost:4200/print?filePath=${firstParam.filePath}`);
    // }

    disableClearAllCache !== null
      ? (disableClearAllCache = true)
      : (this.disableClearAllCache = true);
  }

  async createPrintParameter(printOption: IPrintOption, item: FileInfo, isSelectFile: boolean, isPrintAll?: boolean) {
    const fileId = UtilExtend.getIdFileIdRev(item);
    const adeptInfor = this.permission.getUser(fileId); // get session infor
    const permissionConfig = this.permission.getPermissionOfFile(fileId);
    let waterMarkConfig = isPrintAll ? this.permission.getWaterMarkFileBy(UtilExtend.getFileIdRev(adeptInfor)) : this.permission.getWaterMarkFilePrint();
    if (!waterMarkConfig) waterMarkConfig = new WaterMarkConfig();

    const { modelFileId, viewId, multiStream } = item;
    const mergerInfo = this.getInfoMerger(viewId);
    let lastViewId = viewId;
    let fileIdRev = null;
    if (mergerInfo) {
      fileIdRev = mergerInfo.fileIdRev;
      lastViewId = mergerInfo.parentId;
    } else if (isSelectFile && multiStream) {
      // When a merged file is not displayed in the viewer
      fileIdRev = {
        baseFileId: item.baseFileId,
        baseMajorRev: item.baseMajorRev,
        baseMinorRev: item.baseMinorRev,
      };
    }
    let markupData = null;

    if (adeptInfor?.viewItemParameters?.markupData) {
      markupData = Util.parseJson(adeptInfor.viewItemParameters.markupData);
      // markupData?.modelViews?.forEach((v) => {
      //   v.cameraMarkup = null;
      // });
    }

    if (!markupData && isSelectFile) {
      markupData = await this.multiService.markupsService
        .getMarkupDataFormServerPrintUse(modelFileId, lastViewId, fileIdRev)
        .toPromise();
    }
    // eslint-disable-next-line max-len
    let listGroup = [];
    if (this.multiService?.markupsService?.listMarkupGroups) {
      // eslint-disable-next-line max-len
      listGroup = this.multiService?.markupsService?.listMarkupGroups.filter((itemGroup:IMarkupGroup) => itemGroup.isVisible === false).map((itemGroup:IMarkupGroup) => itemGroup.uniqueId);
    }
    const markupMode = this.panelStatusService.getRightPanelStatus();
    // eslint-disable-next-line max-len
    if (markupData && !isSelectFile) {
      const markupDisable = this.markupsUiService.markupEntitiesDisable$.value;
      // eslint-disable-next-line max-len
      markupData.markupEntities = markupData.markupEntities.filter((markup: MarkupEntity) => !listGroup.includes(markup.uniqueGroupId)); // hidden markup
      // eslint-disable-next-line max-len
      markupData.markupEntities = markupData.markupEntities.filter((markup: MarkupEntity) => !markupDisable.includes(markup.uniqueId)); // hidden markup
      if (!markupMode) {
        markupData.markupEntities = [];
      }
      // // Phương: Nếu có markup thì gán camera bằng cameraMarkup nếu không thì in bằng camera gốc của file
      // if (markupData
      //   && markupData.markupEntities
      //   && markupData.markupEntities.length) {
      //   markupData.modelViews && markupData.modelViews.forEach((modelView) => {
      //     if (modelView) modelView.camera = modelView.cameraMarkup;
      //   });
      // }
    }
    let renderColor = EColorOption.Color;
    if (printOption) renderColor = printOption.color;
    else {
      renderColor = UtilExtend.getValuePermission(
        permissionConfig,
        Permission.RENDERING_COLOR,
        false,
      ) as number;
      if (renderColor) renderColor = EColorOption.Color; else renderColor = EColorOption.Grayscale;
    }

    const printDetail: IViewItemParameters = {
      operation: 'print',
      autoLoadMarkup: true,
      loadFit: true,
      renderingColor: `${renderColor}`, // 0: Color, 1: Gray, 2: B&W
      printHeader: this.createPrintHeader(waterMarkConfig),
      watermark: this.createPrintWatermark(waterMarkConfig),
      autovueRedlineConversionData: '',
      markupData: JSON.stringify(markupData),
      // [may08] [ADV-7197] [tuanbv] su dung gia tri tu config thay vi param truyen sang, vi electron call from othe app
      pageSizeType: SystemConstants.PDF_EXPORT.PAGE_SIZE_TYPE,
      printOption: isPrintAll ? printOption : this.printOption,
      printExtraData: item.printExtraData,
      serverSideConvert: adeptInfor?.viewItemParameters?.serverSideConvert,
    };
    const { baseFileId, baseMajorRev, baseMinorRev } = item;
    // while (baseFileId.includes('print') || baseFileId.includes('-')) {
    //   baseFileId = baseFileId.replace('print', '').replace('-', '');
    // }
    const data: ExtendParameters[] = Util.getValueLocalStorage(CommonConstant.ExtendParameters);
    let parameter = null;
    if (data) parameter = data.find(e => e.id === `${baseFileId}.${baseMajorRev}.${baseMinorRev}`);
    if (!isSelectFile) {
      let pen_name = printOption.pen_override ? printOption.pen_name : '';
      pen_name = Util.removeExt(pen_name); // Loại bỏ ext trước khi gửi đi
      if (parameter) {
        parameter.id = `${baseFileId}.${baseMajorRev}.${baseMinorRev}`;
        parameter.pen_name = pen_name;
      } else {
        parameter = {
          id:`${baseFileId}.${baseMajorRev}.${baseMinorRev}`,
          pen_name: pen_name
        }
      }
    }
    const printParameter: IViewParamsModel = {
      baseFileId,
      baseMajorRev,
      baseMinorRev,
      userId: '', // Anh tuan Check lai UserID
      userLoginName: '', // Anh tuan Check lai UserID
      fileName: item.filename,
      originalFilePath: item.originalFilePath,
      viewItemParameters: printDetail,
      extendParameters: parameter
    };
    return printParameter;
  }

  createPrintHeader(waterMarkConfig: WaterMarkConfig) {
    const { HEADER } = waterMarkConfig;
    const printHeader: IPrintHeader = HEADER;
    return printHeader;
  }

  createPrintWatermark(waterMarkConfig: WaterMarkConfig) {
    const { WATERMARK } = waterMarkConfig;
    const printWatermark: IWatermark = WATERMARK;
    return printWatermark;
  }

  async updateInfoConfiguration(value: any, isDirect: boolean = false) {
    if (isDirect && value?.length > 0) {
      const firstFile = value[0];
      const ext = Util.getFileExt(firstFile?.originalFile).toLowerCase();
      if (ext === 'sldprt' || ext === 'sldasm') {
        const urlExist = this.streamService.checkPathExist({ path: `${firstFile.streamLocation}/file_info.json` });
        if (urlExist) { // chu y: data la list chu ko phai extraData
          const data = await this.streamService.getContentFile({ path: `${firstFile.streamLocation}/file_info.json` }).toPromise();
          this.arrConfiguration = data;
        }
      }
    }
  }

  updateListFileConfiguration(listFilebyGroup: any, isDirect: boolean = false) {
    if (isDirect && listFilebyGroup?.length > 0 && this.arrConfiguration.length > 0) {
      const firstFile = { ...listFilebyGroup[0] };
      const ext = Util.getFileExt(firstFile?.originalFile).toLowerCase();
      this.arrConfiguration.forEach((name: string, index: number) => {
        const newFileName = `${name}.${ext}`;
        if (index === 0) {
          listFilebyGroup[0].id += `-${name}`;
          listFilebyGroup[0].filename = newFileName;
        } else {
          const fileClone = { ...firstFile };
          fileClone.id += `-${name}`;
          fileClone.filename = newFileName;
          listFilebyGroup.push(fileClone);
        }
      });
    }
  }

  getTranslations() {
    return this.languageManagerService.getCurentTranslations();
  }

  downloadPDF(obj: any): Observable<any> {
    return this.http.get(obj.url, { responseType: 'arraybuffer' }).pipe(map(resp => { 
      if (resp.byteLength < 100) {
        throw new Error('Retrying due to insufficient byte length');
      }
      return { pdfBuffer: resp, fileObj: obj }
     }), retryWhen(errors => errors.pipe(delay(1000), take(2))),
      catchError((error) => {
        console.error(`Failed to download PDF from ${obj.url}.`, error);
        throw error;
      })
    );
  }

  async mergePdfs(pdfsToMerge: any) {
    const mergedPdf = await PDFDocument.create();
    mergedPdf.setTitle("Print PDF");
    const actions = pdfsToMerge.map(async obj => {
      try {
          console.log(`PDF Meging ${obj.fileObj.printParams.fileName}`);
          console.log(`Array buffer is ${obj.pdfBuffer.byteLength} in size`);
          const pdf = await PDFDocument.load(obj.pdfBuffer);
          const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
          copiedPages.forEach((page) => {
            mergedPdf.addPage(page);
          });
      } catch (error) {
        console.log("Failed to convert PDF");
      }
      
    });
    await Promise.all(actions);
    const mergedPdfFile = await mergedPdf.save();
    return mergedPdfFile;
  }
}
