import {
  Component, OnInit, Input, ViewEncapsulation, ChangeDetectionStrategy, OnChanges, ChangeDetectorRef,
} from '@angular/core';

export enum ColorSvg {
  light = 'light',
  accent = 'accent',
  dark = 'dark',
  primary = 'primary',
  gray = 'gray',
  gray3 = 'gray-3',
  denim = 'denim',
}

export enum SizeIcon {
  default = 'default',
  small = 'small',
  medium = 'medium',
  mini = 'mini',
  big= 'big',
  large = 'large',
  large40px = 'large-40px',
}

export enum ColorFillType {
  Fill = 'fill',
  Stroke = 'stroke'
}

@Component({
  selector: 'app-mat-icon-svg',
  templateUrl: './mat-icon-svg.component.html',
  styleUrls: ['./mat-icon-svg.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatIconSvgComponent implements OnInit, OnChanges {
  @Input() icon: string = '';

  @Input() color: ColorSvg = ColorSvg.light;

  @Input() size: SizeIcon = SizeIcon.default;

  @Input() text: string = '';

  @Input() modeFill: ColorFillType = ColorFillType.Fill;

  SizeIcon = SizeIcon;

  constructor(private cdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.cdRef.detach();
  }

  ngOnChanges() {
    this.cdRef.detectChanges();
  }
}
