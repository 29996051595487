import { IPrintHeader, IWatermark } from 'src/app/shared/class/global';
import { ViewItemParameters } from './AdeptInfor';

// export interface IPermission {
//   CAN_CONVERT: boolean;
//   CAN_PRINT: boolean;
//   CAN_REDLINE: boolean;
//   CAN_SAVE_REDLINE: boolean;
//   LOAD_MARKUP: boolean;
//   LOADFIT: boolean;
//   AUTO_OPEN_MARKUP: boolean;
//   EDIT_MARKUP: boolean;
//   OPEN_MARKUP: boolean;
//   SAVE_EXISTING_MARKUP: boolean;
//   SAVE_NEW_MARKUP: boolean;
//   DELETE_MARKUP: boolean;
//   FILTER_ATTR_FROM_GUI: boolean;
//   RENDERING_COLOR: boolean;
// }

export enum Permission {
    CAN_CONVERT = 'canConvert',
    CAN_PRINT = 'canPrint',
    CAN_REDLINE = 'canRedline',
    CAN_SAVE_REDLINE = 'Can save redline',
    LOAD_MARKUP = 'canOpenRedline',
    LOAD_FIT = 'loadFit',
    AUTO_OPEN_MARKUP = 'autoLoadMarkup',
    EDIT_MARKUP = 'canEditRedline',
    OPEN_MARKUP = 'canOpenRedline',
    SAVE_EXISTING_MARKUP = 'canSaveExistingRedline',
    SAVE_NEW_MARKUP = 'canSaveNewRedline',
    DELETE_MARKUP = 'canDeleteRedline',
    FILTER_ATTR_FROM_GUI = 'Filter attr from GUI',
    RENDERING_COLOR = 'renderingColor',
    OPERATION = 'operation',
    SAVE_TO_PDF='canSaveToPDF'
}
export interface PermissionExtend {
    name: Permission;
    value: boolean | number;
    disable?: boolean;
}

export class WaterMarkConfig {
  HEADER: IPrintHeader = {
    topLeftText: '',
    topCenterText: '',
    topRightText: '',
    bottomLeftText: '',
    bottomCenterText: '',
    bottomRightText: '',
    fontSize: 12,
    disableHeaders: false,
  };

  WATERMARK: IWatermark = {
    text: '',
    fontName: 'courier',
    fontSize: 24,
    fontStyle: 0,
    orientation: 0,
    position: 'center',
    xFactor: 0.5,
    yFactor: 0.5,
    color: '#0f0f0f',
    alpha: 1, // John Le _ 30/04/2024 _ update alpha default 0.8 to 1. ADV-7608
    disableWatermark: true,
  };

  Init(data: ViewItemParameters) {
    this.HEADER = data.printHeader;
    this.HEADER.fontSize = data.printHeader.fontSize > 0 ? data.printHeader.fontSize : 12;
    this.WATERMARK = data.waterMark;
  }
}
export type CheckPermissionType = 'all' | 'some';
