<section cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container" class="header"
  cdkDragHandle>
  <span>{{'GENERAL.ATTACH_FILE'|translate}}</span>
  <span class="spacer"></span>
  <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon"
    (click)="closeDialog(false)" tabindex="-1">
    <mat-icon>close</mat-icon>
  </button>
</section>
<mat-divider></mat-divider>
<section class="container">
  <div class="content">
    <div class="row">
      <div class="left">
        <mat-label>{{'DIALOG.ATTACHMENT.LINKNAME'|translate}}*: </mat-label>
      </div>
      <div class="right">
        <div class="input-area">
          <input type="text" id="linkName" name="linkName" required minlength="4" [(ngModel)]="linkName" #name="ngModel"
            (input)="changeName($event)" [class.warning]="requiredName && isInputName" [readOnly]="!canEdit">
          <mat-error *ngIf="requiredName && isInputName">{{ "DIALOG.PROMPT_TEXT.ERROR_NAME_TO_BLANK" | translate }}</mat-error>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="left">
        <mat-label>{{'DIALOG.ATTACHMENT.DESCRIPTION'|translate}}:</mat-label>
      </div>
      <div class="right">
        <div class="input-area">
          <textarea matInput [(ngModel)]="description" (input)="changeDescription($event)" [readOnly]="!canEdit"></textarea>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="left">
        <mat-label>{{'DIALOG.ATTACHMENT.URL'|translate}}*:</mat-label>
      </div>
      <div class="right">
        <div class="input-area">
          <div class="select-file">
            <input matInput type="text" id="linkUrl" name="linkUrl" class="form-control" required minlength="4"
            appForbiddenName="bob" [(ngModel)]="fileName" #name="ngModel" [readonly]="true" (input)="changeURL($event)" [class.warning]="requiredUrl && isChooseFile" [disabled]="!data.isCreate || !canEdit">
            <input type="file" hidden #fileSelect>
            <button (click)="onClickFileInputButton()" [matTooltip]="'GENERAL.BROWSE'|translate" [disabled]="!data.isCreate || !canEdit">
              ...
            </button>
          </div>
        </div>
        <mat-error *ngIf="requiredUrl && isChooseFile">{{ "DIALOG.PROMPT_TEXT.ERROR_FILE_EXIST" | translate }}</mat-error>
      </div>
    </div>
    <div class="row">
      <div class="left">
        <mat-label>{{'DIALOG.ATTACHMENT.OPEN_IN'|translate}}: </mat-label>
      </div>
      <div class="right">
        <div class="box">
          <mat-select [(value)]="selected">
            <mat-option *ngFor="let item of openIn" [value]="item.value"
              [disabled]="!(item.value === openAttachType.associatedApplication)">
              {{ item.display | translate}}
            </mat-option>
          </mat-select>
        </div>
      </div>
    </div>
  </div>
</section>
<div class="footer">
  <button mat-stroked-button (click)="closeDialog(true)"
    [disabled]="!canEdit">{{'GENERAL.OK'|translate}}</button>
  <button mat-stroked-button (click)="closeDialog(false)">{{'GENERAL.CANCEL'|translate}}</button>
</div>
<div class="waiting-main" *ngIf="isWaiting">
  <span>{{ "DIALOG.PRINT.WAITTING" | translate }}</span>
</div>