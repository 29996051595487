import { Component, NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { LanguageManagerService } from './main-viewer/services/language-manager.service';
import { SystemService } from './core/services/api/system.service';
import { StandardPageSizeService } from './core/services/api/standard-page-size.service';
import { GoogleAnalyticsEventsService } from './core/services/google-analytic-events-service';
import { PreventDefaultEvent } from './core/class/preventKeyCode';
import { IconSvgService } from './shared/services/icon-svg.service';
import { WindowService } from './core/services/window.service';
import { PermissionService } from './core/services/permission.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private systemService: SystemService,
    private pageSizeService: StandardPageSizeService,
    private translate: TranslateService,
    private languageManagerService: LanguageManagerService,
    private iconSvg: IconSvgService,
    private googleAnalytic: GoogleAnalyticsEventsService,
    private router: Router,
    private windowService: WindowService,
    private permissionService: PermissionService,
    private ngZone: NgZone,
  ) {
    this.init();
  }

  private init() {
    this.iconSvg.registrySvgIcon();
    this.systemService.config();
    this.pageSizeService.config();
    this.languageManagerService.init();
    this.preventDefaultEvent();
    this.googleAnlyticEvent();
    this.windowService.initEventStorage();
    this.permissionService.init();
  }

  private googleAnlyticEvent() {
    this.router.events.subscribe((event: any) => {
      if (event) {
        this.googleAnalytic.setConfig('page', event.urlAfterRedirects);
        this.googleAnalytic.sendConfig('pageview');
      }
    });
  }

  private preventDefaultEvent() {
    const preventClass = new PreventDefaultEvent(this.ngZone);
    preventClass.init();
  }
}
