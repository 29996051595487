<mat-dialog-content cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container">
  <div class="sub-panel-setting" id="container-panel-setting" #subpanelsetting>
    <div class="header" cdkDragHandle (click)="$event.stopPropagation()">
      <span class="left">{{
        "VIEWER3D.NAV_TOOLBAR.MeasureList" | translate
        }}</span>
      <div class="right">
        <!-- <button mat-icon-button (click)="minimize()">
          <mat-icon>{{
            isHidde ? "keyboard_arrow_down" : "keyboard_arrow_up"
            }}</mat-icon>
        </button> -->
        <button mat-icon-button (click)="closeDialog(false)">
          <mat-icon>{{ "close" }}</mat-icon>
        </button>
      </div>
    </div>
    <mat-divider></mat-divider>
    <section *ngIf="!isHidde">
      <section class="content">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%; box-shadow: none">
          <ng-container matColumnDef="check">
            <th mat-header-cell *matHeaderCellDef style="width: 30px">
              <section class="table-header">
                <mat-checkbox
                class="markup-entity-checkbox-show"
                [ngModel]="isCheckAll"
                (click)="$event.stopPropagation()"
                (change)="actionCheckbox($event)">
                </mat-checkbox>
              </section>
            </th>
            <td mat-cell *matCellDef="let element">
              <div>
                <mat-checkbox
                class="markup-entity-checkbox-show"
                [ngModel]="element?.id | checkInArray: (markupsService?.markupUIService?.markupEntitiesSelected$ | async)"
                (click)="$event.stopPropagation()"
                (change)="actionCheckbox($event, element)">
                </mat-checkbox>
              </div>
            </td>
          </ng-container>
          <!-- type Column -->
          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef style="width: 130px">
              <section class="table-header">
                <span class="title-toggle">{{
                  "GENERAL.TYPE" | translate
                  }}</span>
              </section>
            </th>
            <td mat-cell *matCellDef="let element">
              <div>{{ element.display }}</div>
            </td>
          </ng-container>
          <!-- by Column -->
          <ng-container matColumnDef="value">
            <th mat-header-cell *matHeaderCellDef style="width: 200px">
              <section class="table-header">
                <span class="title-toggle">{{
                  "GENERAL.VALUE" | translate
                  }}</span>
                  <button mat-icon-button (click)="delete()" matTooltip="{{ 'GENERAL.DELETE' | translate }}" disabled="{{!isHasSelect}}">
                    <mat-icon style="transform: scale(0.75)">delete</mat-icon>
                  </button>
              </section>
            </th>
            <td mat-cell *matCellDef="let element">
              <div style="display: flex; flex-direction: row; align-items: center">
                <div>{{ element.value }}</div>
                <button mat-icon-button (click)="copy(element)" matTooltip="{{ 'CONTEXTMENU.COPY' | translate }}">
                  <mat-icon style="transform: scale(0.75)">content_copy</mat-icon>
                </button>
                <button mat-icon-button (click)="delete(element)" matTooltip="{{ 'GENERAL.DELETE' | translate }}">
                  <mat-icon style="transform: scale(0.75)">delete</mat-icon>
                </button>
              </div>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </section>
      <div #pageDiv class="footer">
        <section class="container-item" style="width: 50%;">
          <div class="align-left" style="width: 40% !important;">
            <span class="title-toggle">{{
              "VIEWER_SETTINGS.DISPLAY.MEASURE_UNITS" | translate
              }}</span>
          </div>
          <div class="align-right" style="width: 60% !important;">
            <div style="width: 100%;">
              <app-option-custom [optionData]="dataMeasureUnita" [defaultText]="(UnitMultiplier[curentUnitMultiplier])"
                (eventItem)="onMeasureUnitsChange($event)">
              </app-option-custom>
            </div>
          </div>
        </section>
        <mat-paginator #paginator [pageSize]="5"> </mat-paginator>
      </div>
    </section>
  </div>
</mat-dialog-content>