<section 
[ngClass]="{
    'color-accent': notifiedData.status === 1,
    'color-warn-custom': notifiedData.status === 2,
    'color-error-custom': notifiedData.status === 3,
    'color-copy-custom': notifiedData.status === 4
}"
class="container-notify">
    <span>{{notifiedData.message}}</span>
</section>
