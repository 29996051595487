import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertNumber',
})
export class ConvertNumberPipe implements PipeTransform {
  transform(value: number): string | number {
    if (typeof value === 'number') {
      const temp = value / 1000;
      if (temp >= 10) {
        const fixed = Number.parseFloat(`${temp}`).toFixed(1);
        return `${fixed}k`;
      }
    }
    return value;
  }
}
