import { PdfUI } from 'src/@pdf_typings/foxit';
import { IPrintExtraData } from 'src/app/shared/class/global';
import { Property3DViewer, ModeData } from './3dviewer-common';
import ADSettingViewer3D from './ad.setting.viewer3d';
import ADUndoManagerNew from './ad-undo-manager-new';

export interface FileInfo {
  id?: string;
  accountCacheFile?: string;
  baseFileId: string;
  filename: string;
  viewId?: string;
  isBlank?: boolean;
  isHide?: boolean;
  streamLocation?: any;
  thumbnailLocation?: any;
  converter?: string;
  modelFileId?: string;
  index?: number;
  creator?: { id: string, name: string};
  cacheSize?: number | string;
  originalSize?: number | string;
  importAssemblyTreeTime?: number | string;
  importTime?: number | string;
  loadingTime?: number | string;
  createdDate?: Date | string;
  baseMajorRev?: number;
  baseMinorRev?: number;
  originalFile?: string; // Contains original file name with alternate extension replaced by primary one.
  multiStream?: boolean;
  isRootModel?: any; // -1: root; 0: sheet; 1: root
  extraConvertOutput?: string;
  extraConvert?: any; // json of ExtraConvert
  groupByBaseFileId?: string[];
  viewIdChildren?: string[];
  viewIdParent?: string;
  originalFilePath?: string;
  isDataPrintReady?: boolean;
  viewOnlyModeOriginViewId?: string;
  errorMessage?: string;
  cacheFilename?: string;
  zipsInfor?: any;
  isInZipFile?: boolean;
  startViewTime?: number;
  isDirect?: boolean; // dung xac dinh file HOOP da dc chuyen doi thanh FOXIT
  tmpPdfViewer?: PdfUI;
  isComparisonResult?: boolean;
  printExtraData?: IPrintExtraData;
  serverSideConvert?: boolean;
  sourceFile?: string; // Always contains original file name.
  isExtendParam?: boolean;
}

export interface IComparison {
  sourceFile?: FileInfo;
  destFile?: FileInfo;
  resultFile?: FileInfo;
  srcJson?: any,
  destJson?: any,
}

export interface PlotPaperData {
  bottomMargin: number;
  height: number;
  leftMargin: number;
  paperUnit: string; // inch, ???
  rightMargin: number;
  rotation: number;
  topMargin: number;
  width: number;
}

export interface FileSheet {
    countEnt?: number;
    extMaxPoint?: any;
    extMinPoint?: any;
    id: number;
    name: string;
    isActive: boolean;
    is2D: boolean;
    plotPaperData?: PlotPaperData;
    plotTableName?: string;
}

export interface FileWarning {
  index?: number;
  fileName: string;
  filetype: string;
  fileStatus: number;
  message?: string;
}

export interface ExtraConvert { // file_info.json
  FileVersion: string;
  PlotStyleType: number;
  Layers: FileLayer[],
  RootModel3D: boolean;
  Sheets: FileSheet[];
  Warnings: FileWarning[];
  countEntTotal?: number;
}

export interface ZipFileInfo {
  innerPath: string;
  compressedFileSize: number;
  unCompressFileSize: number;
  percentageOfCompression: number;
  fileModifiedDate: string;
  fileModifiedTime: string;
  hasPassword: boolean;
  fileName: string;
  cacheStatus: number;
  id: string;
  baseFileId: string;
  baseMajorRev: number;
  baseMinorRev: number;
}
export interface ViewActive {
  index: number | null;
  baseFileId: string | null;
  baseMajorRev: number;
  baseMinorRev: number;
  name?: string;
  data: FileInfo;
  isBlank?: boolean;
  viewId: string;
  format?: FormatViewer;
  adUndoManagerNew?: ADUndoManagerNew;
}

export enum TypeLayout {
  Full = 'full',
  TwoAndTwo = '2And2',
  TwoOnOne = '2On1',
  OneOnTwo = '1On2',
  OneAndTwo = '1And2',
  TwoAndOne = '2And1',
  OneAndOne = '1And1',
  OneOnOne = '1On1',
  ViewOnly = 'visible',
}

export enum DirectionLayout {
  Horizontal = 'H',
  Vertical = 'V',
}

export enum TypeZoom {
  In = 'In',
  Out = 'Out',
  Select = 'Select',
  Fit = 'Fit',
  FiftyPercent = '50%',
  OnehundredPecent = '100%',
  TwohundredPecent = '200%',
}

export enum MarkupViewStatus {
  Open,
  Accepted,
  Rejected,
  None,
}

export interface SheetData {
  id?: string; // view id
  nodeId: number;
  name: string;
  isActive: boolean;
  nodeChildrenIds: number[];
  modelFileId: string;
  multiStream?: boolean;
  isLayer?: boolean
  isSelected?: boolean;
}
export interface LayerData {
  id?: string; // view id
  nodeId: number;
  handleId: string;
  name: string;
  isActive: boolean;
  nodeChildrenIds: number[];
  modelFileId: string;
  multiStream?: boolean;
  isSelected?: boolean;
  isShow: boolean;
  isShowAll: boolean;
  isPlot: boolean;
  isOn: boolean;
  isFreeze: boolean;
  isLayer?: boolean;
  isPublishPdf: boolean;
  isPublishPdfAll: boolean;
  isInSheet?: boolean;
}

export interface FileLayer {
  name: string;
  handleId: string;
  on: boolean;
  freeze: boolean;
  vpFreeze: boolean;
  lock: boolean;
  plot: boolean;
}

export interface MarkupViewEvent {
  sheetId: number | null;
}

export interface UserSetting {
  name?: string;
  layout?: TypeLayout;
  token: string;
  info: any;
}

export interface UserInfo {
  id: string;
  userName: string;
  loginName?: string;
  email?: string;
  phoneNumber?: string;
}

export interface SavedViews {
  _id?: string;
  Id?: string;
  uniqueId?: string;
  Name?: string;
  ModelFileId?: string;
  viewType?: ViewType;
  thumnail?: string;
  createdDate?: number;
  modifiedDate?: number;
  createdBy?: string;
  pageRotate?: number;
}
export interface CadViewData {
  Id: string;
  Name: string;
  ModelFileId?: string;
}

export interface CustomView extends SavedViews {
  SheetId?: number | null;
  NodesShow?: number[];
  NodesHide?: number[];
  NodeSetting?: any;
  ShowLineWeight?: boolean;
  CuttingPlane?: any;
  Camera?: any;
  isolateMode?: number;
  explodeMagnitude?: number;
  drawMode?: Communicator.DrawMode;
  NodeConfig?: number;
}
export interface INodeSetting{
  nodeId: number;
  color: Communicator.Color;
  opacity: number;
}
export enum ContextMenuEdit {
  Rename = 1,
  Delete = 2,
  DeleteAll = 3,
}
export enum ContextMenuLayer {
  showAll = 2,
  hideAll = 1,
}

export enum TreeSheetViewTab {
  Tree = 'Tree', // Content: Objects
  Sheet = 'Sheet', // Content: Sheets
  Views = 'Views', // View: Views
  Pages = 'Pages', // Content: Pages
  Documents = 'Documents', // View: Documents
  Layers = 'Layers', // Content: Content
}

export interface StateViewer {
  leftTabName?: TreeSheetViewTab;
  indexNodeTreeSelected?: number;
  viewsIdActive?: string;
  statePropertyPanel?: boolean;
}

export interface DataEmitProperties {
  mode: TreeSheetViewTab;
  data: Property3DViewer[];
}

export enum ModeChangeDetection {
  MainViewer = 1,
  LeftPanel,
  TopPanel,
  RightPanel,
  ContentPanel,
  BottomPanel,
  MenuPanel,
  TreeViewsPanel,
}

export interface ADUserSettings {
  username: string;
  guid: string;
  settings: ADSettingViewer3D;
}

export enum FormatViewer {
  Viewer3d = 1,
  Pdf,
  Zip,
}

export interface INodeFormat {
  id: number;
  color: Communicator.Color;
  opacity:number
}

export interface IIsolateInfor {
  nodeIds: number[];

  drawMode: Communicator.DrawMode;
}

export interface IGhostingInfor {
  nodeIds: number[];

  drawMode: Communicator.DrawMode;
}

export enum ViewType {
  SaveView = 0,
  MarkupView, // 1
  ModelView, // 2
}

export interface INotePinItem {
  noteTextId: string;
  noteText: Communicator.Markup.Note.NoteText;
  fontText: string;
  fontSize: number;
  fontColor: Communicator.Color;
}

export interface FileIdRev {
  baseFileId: string;
  baseMajorRev: number;
  baseMinorRev: number;
}

export interface ResponseViews {
  cadViews?: CadViewData[];
  savedViews?: CustomView[];
}

export interface Properties {
  name: string;
  value: string;
  type?: string;
  isDate?: boolean;
  isLayer?: boolean;
  icon?: string
}

export interface ResultSearchTree<T> {
  index: number;
  node: T;
}

export interface CadConfigurationInfo {
  id?: number;
  name: string;
  tooltip?: string;
}

export interface CurrentCadConfigView {
  id?: number;
  view: 'Current view' | 'Save view';
}

export interface ResponseModelFiles {
  id: string;
  filename: string;
  baseFileId: string;
  baseMajorRev: number;
  baseMinorRev: number;
  createdDate?: Date | string;
}

export type PartialType<T> = { [P in keyof T]?: T[P]; };
