<div style="display: flex; flex-direction: row; align-items: center;">
  <mat-icon
    *ngIf="icon"
    svgIcon="{{ icon }}"
    [ngClass]="{
      'mat-svg-icon-custom': true,
      'color-dark-svg': color === 'dark',
      'color-accent-svg': color === 'accent',
      'color-light-svg': color === 'light',
      'color-gray-svg': color === 'gray',
      'color-gray-3-svg': color === 'gray-3',
      'color-primary-svg': color === 'primary',
      'color-denim-svg': color === 'denim',
      'size-mini': size === SizeIcon.mini,
      'size-small': size === SizeIcon.small,
      'size-medium': size === SizeIcon.medium,
      'size-big': size === SizeIcon.big,
      'size-large': size === SizeIcon.large,
      'size-large-40px': size === SizeIcon.large40px
    }"
  >
  </mat-icon>
  <div *ngIf="icon && text !== ''" style="width: 5px"></div>
  <span
    *ngIf="text !== ''"
    [ngClass]="{
      'color-dark-text': color === 'dark',
      'color-accent-text': color === 'accent',
      'color-light-text': color === 'light',
      'color-gray-text': color === 'gray',
      'color-gray-3-text': color === 'gray-3',
      'color-primary-text': color === 'primary'
    }"

    style="height: 34px"
    >{{ text }}</span
  >
</div>
