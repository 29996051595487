import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IStandard } from 'src/app/shared/class/global';
import { SystemConstants } from '../../common/system.constants';

interface IStandardPageSize {
  Metric: IStandard[]
  Imperial: IStandard[]
}

@Injectable({
  providedIn: 'root',
})

export class StandardPageSizeService {
  private readonly path = '/assets/data/standard-page-size.json';

  constructor(
    private http: HttpClient,
  ) { }

  config() {
    this.http
      .get(`${this.path}`)
      .pipe()
      .subscribe(
        (value: IStandardPageSize) => {
          this.setConfig(value);
        },
        (err) => this.handlerErr(err),
      );
  }

  private setConfig(value: IStandardPageSize) {
    const Imperial = value.Imperial.map((e, index) => ({ ...e, value: index }));
    const Metric = value.Metric.map((e, index) => ({ ...e, value: index }));
    SystemConstants.Metric = Metric;
    SystemConstants.Imperial = Imperial;
  }

  private handlerErr(err: any) {
    console.log(`Error get file config: ${err}`);
  }
}
