import { MultiViewService } from 'src/app/core/services/multi-view.service';
import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LanguageManagerService } from 'src/app/main-viewer/services/language-manager.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ModeUpdate, StatusNotify } from 'src/app/core/common/global';
import { PdfCommunicator } from 'src/app/pdf/service/PdfCommunicator';
import { MarkupManager } from 'src/app/pdf/service/markup/MarkupManager';
import { OptionData } from 'src/app/shared/common/shared.common';
import {
  MarkupType, ObjectType, UnitMultiplier, eUnitsName,
} from 'src/app/shared/class/global';
import ADWebViewer from 'src/app/core/common/ad-web-viewer';
import { UtilExtend } from 'src/app/core/utils/util-extend';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Permission } from 'src/app/core/common/permission';
import { PermissionService } from 'src/app/core/services/permission.service';
import { MarkupEntity } from 'src/app/core/common/markups';
import { MarkupsService } from 'src/app/core/services/markups.service';
import Util from 'src/app/core/utils/util';
import { NotifyComponent } from '../../../common/notify/notify.component';

interface IMeasure {
  id: string;
  type: string;
  display: string,
  value: string;
  measureData: any;
}
@Component({
  selector: 'app-measure-list',
  templateUrl: './measure-list.component.html',
  styleUrls: ['./measure-list.component.scss'],
})
export class MeasureListComponent implements OnInit {
  isCheckAll = true;

  isHasSelect = false;

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (this.pdfController) {
      const viewer = this.pdfController.getViewer();
      const {
        dialogService,
      } = viewer;
      if (dialogService.dialogExtend.isShowDialogConfim) {
        return;
      }
    }
    this.dialogRef && this.dialogRef.close();
  }

  curentUnitMultiplier: UnitMultiplier;

  UnitMultiplier = UnitMultiplier;

  dataMeasureUnita: OptionData<number>[] = [
    {
      name: eUnitsName.Millimeter,
      value: UnitMultiplier.Millimeter,
    },
    {
      name: eUnitsName.Centimeter,
      value: UnitMultiplier.Centimeter,
    },
    {
      name: eUnitsName.Decimeter,
      value: UnitMultiplier.Decimeter,
    },
    { name: eUnitsName.Meter, value: UnitMultiplier.Meter },
    {
      name: eUnitsName.Dekameter,
      value: UnitMultiplier.Dekameter,
    },
    {
      name: eUnitsName.Hectometer,
      value: UnitMultiplier.Hectometer,
    },
    {
      name: eUnitsName.Kilometer,
      value: UnitMultiplier.Kilometer,
    },
    { name: eUnitsName.Point, value: UnitMultiplier.Point },
    { name: eUnitsName.Inch, value: UnitMultiplier.Inch },
    { name: eUnitsName.Picas, value: UnitMultiplier.Picas },
    { name: eUnitsName.Feet, value: UnitMultiplier.Feet },
    { name: eUnitsName.Yard, value: UnitMultiplier.Yard },
    { name: eUnitsName.Mile, value: UnitMultiplier.Mile },
    {
      name: eUnitsName.Megameter,
      value: UnitMultiplier.Megameter,
    },
    {
      name: eUnitsName.MicroInch,
      value: UnitMultiplier.MicroInch,
    },
    { name: eUnitsName.Mil, value: UnitMultiplier.Mil },
    {
      name: eUnitsName.Picometer,
      value: UnitMultiplier.Picometer,
    },
    {
      name: eUnitsName.Angstroms,
      value: UnitMultiplier.Angstroms,
    },
    {
      name: eUnitsName.Nanometer,
      value: UnitMultiplier.Nanometer,
    },
    {
      name: eUnitsName.Micrometer,
      value: UnitMultiplier.Micrometer,
    },
    {
      name: eUnitsName.Gigameters,
      value: UnitMultiplier.Gigameters,
    },
    {
      name: eUnitsName.AstronomicalUnits,
      value: UnitMultiplier.AstronomicalUnits,
    },
    {
      name: eUnitsName.LightYears,
      value: UnitMultiplier.LightYears,
    },
    {
      name: eUnitsName.Parsec,
      value: UnitMultiplier.Parsec,
    },
    {
      name: eUnitsName.USSurveyFeet,
      value: UnitMultiplier.USSurveyFeet,
    },
  ];

  displayedColumns: string[] = ['check', 'type', 'value'];

  measures: IMeasure[] = [];

  dataSource = new MatTableDataSource<IMeasure>(this.measures);

  isHidde = false;

  webViewer: Communicator.WebViewer;

  markupManager: MarkupManager;

  pdfController: PdfCommunicator;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  private onDestroy$ = new Subject<any>();

  markupsService: MarkupsService;

  multiViewService: MultiViewService;

  listMarkupIdSelected: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<MeasureListComponent>,
    private cdRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      webViewer: Communicator.WebViewer;
      PdfController: PdfCommunicator;
      MarkupManager: MarkupManager;
    },
    private clipboard: Clipboard,
    private snackBar: MatSnackBar,
    private languageManagerService: LanguageManagerService,
    private permissionService: PermissionService,
  ) {
    if (data.MarkupManager) {
      this.markupManager = data.MarkupManager;
    }
    if (data.PdfController) {
      this.pdfController = data.PdfController;
      this.markupsService = this.pdfController.getViewer().markupService;
      this.multiViewService = this.pdfController.getMultiViewService();
      this.curentUnitMultiplier = this.pdfController.getUnitMeasure();
    }
    if (data.webViewer instanceof ADWebViewer) {
      this.webViewer = data.webViewer;
      this.markupsService = data.webViewer.markupsService;
      this.multiViewService = data.webViewer.markupsService.multiViewService;
      const MeasureUnit = data.webViewer.getMeasureUnit();
      const MeasureUnitModel = data.webViewer.getMeasureUnitModel();
      this.curentUnitMultiplier = MeasureUnit !== MeasureUnitModel ? MeasureUnit : MeasureUnitModel;
    }
    const initUnit = this.getUnitsMeasure();
    if (initUnit) {
      const measureUnit: UnitMultiplier = initUnit;
      this.curentUnitMultiplier = measureUnit;
    }
  }

  init() {
    if (this.markupsService && this.markupsService.markupUIService) {
      this.markupsService.markupUIService.markupEntitiesSelected$.pipe(takeUntil(this.onDestroy$)).subscribe((ids: string[]) => {
        this.updateSelected(ids);
        this.updateDetectChange();
      });
    }
    if (this.markupsService) {
      let measureUnitModel = 1.0;
    if (this.webViewer instanceof ADWebViewer) measureUnitModel = this.webViewer.measureUnitModel;
      this.markupsService.listMarkupEntities$.pipe(takeUntil(this.onDestroy$)).subscribe((markupEntities) => {
        const translation = this.languageManagerService.getCurentTranslations();
        this.measures = [];
        markupEntities.forEach((markupEntity) => {
          const arrDimention: MarkupType[] = Util.DimentionType;
          if (arrDimention.includes(markupEntity.type)) {
            let value = '';
            let type = '';
            let display = '';
            if (markupEntity.originData.contents) {
              value = markupEntity.originData.contents;
              type = markupEntity.originData.intent;
            } else {
              value = Util.getDisplayMarkupDimValue(markupEntity.originData.measurementValue, markupEntity.originData.unitMultiplier, measureUnitModel);
              type = markupEntity.originData.name;
            }
            switch (type) {
              case 'MeasurePointPointDistance':
              case 'LineDimension':
                display = translation.MARKUPS.DISTANCES.POINT_TO_POINT;
                break;
              default:
            }
            const data: IMeasure = {
              id: markupEntity.uniqueId,
              type,
              display,
              value,
              measureData: markupEntity,
            };
            this.measures.push(data);
          }
        });
        console.log(this.measures);
        this.dataSource.data = this.measures;
        if (this.markupsService && this.markupsService.markupUIService) {
          const ids = this.markupsService.markupUIService.markupEntitiesSelected$.value || [];
          this.updateSelected(ids);
        }
        this.updateDetectChange();
      });
    }
  }

  updateSelected(ids: string[]) {
    const listStatus = [];
    this.isCheckAll = true;
    this.isHasSelect = false;
    for (let i = 0; i < this.measures.length; i++) {
      const element = this.measures[i];
      const temp = ids.find((m) => m === element.measureData.uniqueId);
      let check = false;
      if (!temp) {
        check = false;
      } else {
        check = true;
      }
      listStatus.push(check);
    }
    this.isHasSelect = listStatus.includes(true);
    if (listStatus.length > 0) {
      this.isCheckAll = !listStatus.includes(false);
    } else this.isCheckAll = false;
  }

  updateDetectChange(): void {
    this.cdRef.detectChanges();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy(): void {
    UtilExtend.onDestroy(this.onDestroy$);
  }

  ngOnInit(): void {
    this.init();
  }

  closeDialog(status: boolean) {
    this.dialogRef.close();
  }

  copy(item: IMeasure) {
    const translation = this.languageManagerService.getCurentTranslations();
    if (item) {
      const measurementText = item.value;
      this.clipboard.copy(measurementText);
      this.snackBar.openFromComponent(NotifyComponent, {
        panelClass: 'custom-container-notify',
        data: {
          message: `${translation.CONTEXTMENU.COPY} ${measurementText}`,
          status: StatusNotify.copy,
        },
        duration: 1000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
      });
    }
    this.updateDetectChange();
  }

  delete(item?: IMeasure) {
    if (!this.permissionService.hasPermissions(Permission.DELETE_MARKUP)) {
      if (this.webViewer && this.webViewer instanceof ADWebViewer) {
        const translations = this.languageManagerService.getCurentTranslations();
        const msg = translations.DIALOG.Notify.Delete;
        this.markupsService.dialogExtendService.notified({
          message: msg,
          status: StatusNotify.warning,
        });
        return;
      }
    }
    const dialog = this.markupsService.removeMarkupEntityConfirm(false);
    dialog.afterClosed().pipe(take(1)).subscribe((result) => {
      if (result) {
        if (item) {
          this.markupsService.removeMarkupEntity([item.measureData.uniqueId]);
        } else {
          const listAllMeasure = this.markupsService.getMarkupsSelected()
            .filter((mk) => Util.DimentionType.includes(mk.type))
            .map((mk) => mk.uniqueId);
          this.markupsService.removeMarkupEntity(listAllMeasure);
        }
      }
      this.markupsService.dialogExtendService.isShowDialogConfim = false;
    });
  }

  onMeasureUnitsChange(event: OptionData<string>) {
    this.curentUnitMultiplier = UnitMultiplier[event.value];
    if (typeof event.value === 'number') {
      const val = event.value;
      this.setUnitsMeasure(val);
      if (this.webViewer instanceof ADWebViewer) {
        // this.webViewer.setMeasureUnit(val);
        const listAllMergerFile = this.markupsService.fileListsService.getAllFileInfoOfFileMerged();
        if (listAllMergerFile) {
          listAllMergerFile && listAllMergerFile.forEach((file) => {
            const modelInfo = this.markupsService.multiViewService.getModelInfo(file.viewId);
            if (modelInfo && modelInfo.webviewer instanceof ADWebViewer) modelInfo.webviewer.setMeasureUnit(val);
          });
        } else {
          this.webViewer.setMeasureUnit(val);
        }
      }
      if (this.markupManager) {
        // Cập nhật measurementInfo cho các lần vẽ Dimensions sau
        const { measurementInfo } = this.pdfController.pdfViewer;
        measurementInfo.unit = Util.getSymbolUnit(val).trim();
        measurementInfo.ratio.userSpaceValue = val;
        const markups = this.markupsService.getMarkupsSelected();
        const annots = [];
        const dimentions: MarkupType[] = Util.DimentionType;
        markups.forEach((markup) => {
          if (dimentions.includes(markup.type)) {
            annots.push(markup.originData);
          }
        });
        if (annots.length) {
          this.markupManager.ChangeUnitMeasure(event.value, annots);
        }
      }
    }
    this.updateDetectChange();
  }

  minimize() {
    const width = '600px';
    const height = '407px';
    this.isHidde = !this.isHidde;
    console.log(this.dialogRef);

    if (this.isHidde) {
      this.dialogRef.updateSize(width, '41px');
      // this.dialogRef.updatePosition({
      //   top: "0px",
      //   left: "0px",
      // })
    } else {
      this.dialogRef.updateSize(width, height);
      // this.dialogRef.updatePosition(null);
    }
  }

  actionCheckbox($event: any, data?: IMeasure) {
    if (!data) {
      const listNew: IMeasure[] = [];
      const ids = [];
      this.measures.forEach((item) => {
        const temp = item;
        ids.push(temp.measureData.uniqueId);
        listNew.push(temp);
        if (this.pdfController) {
          if ($event.checked) {
            this.activeMarkupPDF(item.measureData, true);
          } else {
            this.markupsService.deActiveMarkup(item.measureData as MarkupEntity);
          }
        }
      });
      this.measures = listNew;
      if ($event.checked) {
        this.markupsService.markupUIService.updateMap(ids, ModeUpdate.EntitiesSelected);
      } else {
        this.markupsService.markupUIService.clearMap(ModeUpdate.EntitiesSelected, null, true, ids);
      }
      this.activeMarkupAllHoop($event.checked);
      return;
    }
    if ($event.checked) {
      if (!this.markupsService.markupUIService.firstMarkupSelected) {
        this.markupsService.markupUIService.updateFistSelect(data.measureData, ObjectType.markup);
      }
      if (this.pdfController) {
        this.activeMarkupPDF(data.measureData, true);
      }
      this.markupsService.markupUIService.updateMap(data.measureData.uniqueId, ModeUpdate.EntitiesSelected);
    } else {
      this.markupsService.markupUIService.updateCheckbox(data.measureData.uniqueId, false, ModeUpdate.EntitiesSelected);
      this.markupsService.deActiveMarkup(data.measureData as MarkupEntity);
      this.updateFirstMarkupEntitySelected(data.measureData as MarkupEntity);
    }
    if (this.webViewer) {
      this.activeMarkupHoop(data.measureData, $event.checked);
    }
    // this.activeMarkup(object, true);
  }

  private updateFirstMarkupEntitySelected(data: MarkupEntity) {
    const markupsSelects = this.markupsService.markupUIService.markupEntitiesSelected$.value;
    if (markupsSelects.length === 0) {
      // this.markupsService.markupUIService.firstMarkupSelected = null;
      this.markupsService.markupUIService.updateFistSelect(null, ObjectType.markup, true);
      this.markupsService.markupUIService.itemStartMultiSelectEntity = null;
    } else if (!this.markupsService.markupUIService.firstMarkupSelected) {
      // this.markupsService.markupUIService.firstMarkupSelected = data as MarkupEntity;
      this.markupsService.markupUIService.updateFistSelect(data, ObjectType.markup);
      this.markupsService.markupUIService.itemStartMultiSelectEntity = null;
    }
  }

  activeMarkupPDF(object, isCheckbox?: boolean) {
    this.markupsService.canActiveMarkup = false;
    this.markupsService.onSlectObjectEntity(object, 1, isCheckbox);
    setTimeout(() => {
      this.markupsService.canActiveMarkup = true;
      this.markupsService.markupUIService.updateMap(object.uniqueId, ModeUpdate.EntitiesActive, false, false);
    }, 350);
  }

  activeMarkupHoop(object, isCheckbox?: boolean) {
    if (this.webViewer) {
      const measures = this.webViewer.measureManager.getAllMeasurements();
      const measure = measures.find((m: any) => m.getOriginId() === object.originData.originId);
      if (measure) {
        if (isCheckbox) {
          if (this.webViewer instanceof ADWebViewer) {
            this.webViewer.markupMultiselectService.selectMarkup(measure);
          }
        } else {
          measure.onDeselect();
        }
      }
    }
  }

  activeMarkupAllHoop(isCheckbox?: boolean) {
    if (this.webViewer) {
      const measures = this.webViewer.measureManager.getAllMeasurements();
      measures.forEach((m) => {
        if (!isCheckbox) {
          m.onDeselect();
        } else if (this.webViewer instanceof ADWebViewer) {
          this.webViewer.markupMultiselectService.selectMarkup(m);
        }
      });
    }
  }

  getFileInfor() {
    const parentViewId = this.markupsService.fileListsService.getViewId(this.multiViewService?.viewActive?.viewId);
    const fileInfor = parentViewId ? this.markupsService.fileListsService.getFileInfo(parentViewId) : null;
    return fileInfor;
  }

  setUnitsMeasure(val: number) {
    const fileInfo = this.getFileInfor();
    let unitsMesure = JSON.parse(localStorage.getItem(Util.UNITS_MEASURE));
    if (fileInfo) {
      const { baseFileId } = fileInfo;
      const title = baseFileId.toString();
      if (unitsMesure) {
        unitsMesure[title] = val;
      } else {
        unitsMesure = { [title]: val };
      }
      localStorage.setItem(Util.UNITS_MEASURE, JSON.stringify(unitsMesure));
    }
  }

  getUnitsMeasure() {
    const fileInfo = this.getFileInfor();
    const unitsMesure = JSON.parse(localStorage.getItem(Util.UNITS_MEASURE));
    if (fileInfo && unitsMesure) {
      const { baseFileId } = fileInfo;
      const title = baseFileId.toString();
      return unitsMesure[title];
    }
    return null;
  }
}
